import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import ScrollToTopOnMount from '../Common/ScrollToTopOnMount';
import Zoom from '@material-ui/core/Zoom';

const contentslist = [
    {
        url: `${process.env.PUBLIC_URL}/static/images/html5.jpg`,
        title: 'HTML5',
        width: '50%',
        to: '/html5',
    },
    {
        url: `${process.env.PUBLIC_URL}/static/images/css3.jpg`,
        title: 'CSS3',
        width: '50%',
        to: '/css3',
    },
    {
        url: `${process.env.PUBLIC_URL}/static/images/javascript.jpg`,
        title: 'Javascript',
        width: '50%',
        to: '/js',
    },
    {
        url: `${process.env.PUBLIC_URL}/static/images/elecbook.jpg`,
        title: '電子書籍',
        width: '50%',
        to: '/epub',
    },

];

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      minWidth: 240,
      width: '100%',
    },
    image: {
        position: 'relative',
        height: 200,
        [theme.breakpoints.down('xs')]: {
          width: '100% !important', // Overrides inline-style
          height: 100,
        },
        '&:hover, &$focusVisible': {
          zIndex: 1,
          '& $imageBackdrop': {
            opacity: 0.15,
          },
          '& $imageMarked': {
            opacity: 0,
          },
          '& $imageTitle': {
            border: '4px solid currentColor',
          },
        },
      },
      focusVisible: {},
      imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
      },
      imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
      },
      imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
      },
      imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
      },
      imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
      },

}));

export default function Home() {
    const classes = useStyles();
    return (
        <Zoom in={true}>
          <div>
          <ScrollToTopOnMount />
          <div className={classes.root}>
          <br/>
          {contentslist.map(content => (
                <ButtonBase
                focusRipple
                key={content.title}
                className={classes.image}
                focusVisibleClassName={classes.focusVisible}
                style={{
                    width: content.width,
                }}
                component={Link}
                to={content.to}
                >
                <span
                    className={classes.imageSrc}
                    style={{
                    backgroundImage: `url(${content.url})`,
                    }}
                />
                <span className={classes.imageBackdrop} />
                <span className={classes.imageButton}>
                    <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    className={classes.imageTitle}
                    >
                    {content.title}
                    <span className={classes.imageMarked} />
                    </Typography>
                </span>
                </ButtonBase>
            ))}

          </div>
          </div>
        </Zoom>
      );
}
