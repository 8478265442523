import React, { Component } from 'react';
import { Route,Switch } from 'react-router-dom';

// コンテナ読み込み
import ResponsiveDrawer from './ResponsiveDrawer/ResponsiveDrawer';
import Home from './Home/Home.js';
import Company from './Company/Company.js';
import Poricy from './Poricy/Poricy.js';
import Footer from './Footer/Footer.js'
import Contents from './Contents/Contents.js';
import Moeropro from './Moeropro/Index.js';
import Html5 from './Moeropro/Html5.js';
import Css3 from './Moeropro/Css3.js'
import Epub from './Moeropro/Epub.js';
import Js from './Moeropro/Js.js';
class App extends Component {

  render() {
    return (
      <div>
        <ResponsiveDrawer>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/company" component={Company}/>
            <Route exact path="/poricy" component={Poricy}/>
            <Route exact path="/contents" component={Contents}/>
            <Route exact path="/moeropro" component={Moeropro}/>
            <Route exact path="/html5" component={Html5}/>
            <Route exact path="/css3" component={Css3}/>
            <Route exact path="/epub" component={Epub}/>
            <Route exact path="/js" component={Js}/>
            <Route component={Home} />
          </Switch>
        </ResponsiveDrawer>
        <Footer />
      </div>
    );
  }
}

export default App;
