import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ScrollToTopOnMount from '../Common/ScrollToTopOnMount';
import Zoom from '@material-ui/core/Zoom';
import Grid from '@material-ui/core/Grid';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import '../Common/Common.css';
import '../Common/Moeropro.css';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LinkBtn from '@material-ui/core/Link';
import { Link } from 'react-router-dom';

export default function Epub() {

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);

  const handleClick1 = () => {
    setOpen1(!open1);
  };
  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const handleClick3 = () => {
    setOpen3(!open3);
  };

  return (
    <Zoom in={true}>
    <div>
      <ScrollToTopOnMount />
      <Typography variant='h6' className='Sub-title' gutterBottom>
        <Box fontWeight='fontWeightBold'>電子書籍作成指南</Box>
      </Typography>
      <Grid container>
        <Grid item xs={12} md={12}>
          <List component="nav" aria-labelledby="nested-list-subheader" className="root">
            <ListItem button onClick={handleClick1}>
              <ListItemText primary="１．環境構築" />
              {open1 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <AnchorLink href='#1-1' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="１−１．はじめに" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#1-2' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="１−２．Sigilのダウンロード・インストール" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#1-3' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="１−３．Sigilの日本語化" />
                  </ListItem>
                </AnchorLink>
              </List>
            </Collapse>
            <ListItem button onClick={handleClick2}>
              <ListItemText primary="２．Sigilで文章を書いてみよう" />
              {open2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <AnchorLink href='#2-1' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="２−１．文章を書いて電子書籍化してみよう" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#2-2' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="２−２．書いた文章を確認してみよう～Sigil編～" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#2-3' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="２−３．書いた文章を確認してみよう～KindlePreviewer編～" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#2-4' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="２−４．注意点" />
                  </ListItem>
                </AnchorLink>
              </List>
            </Collapse>
            <ListItem button onClick={handleClick3}>
              <ListItemText primary="３．電子書籍を出版してみよう" />
              {open3 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open3} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <AnchorLink href='#3-1' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−１．アカウント作成" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-2' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−２．KDPで出版" />
                  </ListItem>
                </AnchorLink>
              </List>
            </Collapse>
          </List>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="ad">
            広告エリア１
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <div className="main_content">
            <h2>１．環境構築</h2>
            <section id='1-1'>
      				<h3>１−１．はじめに</h3>
      				<p className="line">ここでは電子書籍の作成～出版までをさくっとできるように説明するコンテンツです。</p>
      				<p className="line">開発環境はWindows10、電子書籍はEPUB3をベースとしHTML＆CSSで作成していきます。<br/>また出版はAmazon Kindle ダイレクトパブリッシングで行います。</p>
            </section>
            <section id='1-2'>
              <h3>１−２．Sigilのダウンロード・インストール</h3>
              <p className="line">電子書籍を作成するにあたり「<span className="emphasis">Sigil</span>」というツールを利用します。<br/>
              Sigilは「<a href="https://sigil-ebook.com/" target="_blank" rel="noreferrer noopener">https://sigil-ebook.com/</a>」よりインストーラをダウンロードしてインストールするだけで利用可能になります。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/1-1-1.png`}/>
              <p className="line">遷移先のページ最下部にあるリンクより、自分の環境にあったインストーラをダウンロードします。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/1-1-2.png`}/>
              <p className="line">ダウンロードしたインストーラを実行します。<br/>起動したら「I accept the agreement」にチェックをいれてNextボタンをクリックします。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/1-1-3.png`}/>
              <p className="line">次はそのままNextボタンをクリックします。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/1-1-4.png`}/>
              <p className="line">次はそのままInstallボタンをクリックすれば完了です。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/1-1-5.png`}/>
            </section>
            <section id='1-3'>
              <h3>１−３．Sigilの日本語化</h3>
              <p className="line">インストールしたばかりだとSigilは英語になっていますのでここでは日本語化を行います。<br/>「Edit→Preferences...」をクリックします。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/1-3-1.png`}/>
              <p className="line">Languageをクリックし、2つのプルダウンをJapaneseを選択してOKボタンをクリックします。<br/>Sigilを再起動すると日本語が適用されます。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/1-3-2.png`}/>
            </section>
            <h2>２．Sigilで文章を書いてみよう</h2>
            <section id='2-1'>
              <h3>２−１．文章を書いて電子書籍化してみよう</h3>
              <p className="line">さっそくここからは文章を書いて電子書籍化（以下EPUB化）をしていきます。<br/>冒頭でも書いた通り文章はHTML＋CSSで書いていきます。
              <br/>書き方が分からない方は
              <LinkBtn component={Link} to="/html5" underline="always" target="_blank">HTMLはこちら</LinkBtn>、
              <LinkBtn component={Link} to="/css3" underline="always" target="_blank">CSSはこちら</LinkBtn>
              を参考にしましょう。</p>
              <p className="line">EPUB化をするにあたって大きく分けて「本のタイトル設定」「表紙」「目次」「本文の作成」の４つが最低限必要となります。
              <br/>「表紙」については
              <AnchorLink href='#3-2' offset='80'>
              ３−２．出版
              </AnchorLink>
              で説明しますのでまずは「本のタイトル設定」を行います。</p>
              <p className="line2">■本のタイトル設定</p>
              <p className="line">画像にある赤枠のアイコン（メタデータエディタ）をクリックします。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/2-1-1.png`}/>
              <p className="line">ダイアログが開きますので、「dc:title」の値をクリックし本のタイトルを入力します。<br/>入力をしたらOKボタンをクリックすると設定完了です。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/2-1-2.png`}/>
              <p className="line">&nbsp;</p>
              <p className="line2">■本文の作成</p>
              <p className="line">次に目次の作成といきたいところですが、まずは本文の作成から説明していきます。<br/>まず、最初から作成されている「Section0001.xhtml」ファイルを開きます。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/2-1-3.png`}/>
              <p className="line">開いたファイルにHTML形式で文章を書いていきます。例えば以下のように書いてみましょう。</p>
              <p className="sample">
              <br/>
              &lt;?xml version="1.0" encoding="utf-8"?&gt;<br/>
              &lt;!DOCTYPE html&gt;<br/>
              <br/>
              &lt;html xmlns="http://www.w3.org/1999/xhtml" xmlns:epub="http://www.idpf.org/2007/ops"&gt;<br/>
              &lt;head&gt;<br/>
              &lt;title&gt;&lt;/title&gt;<br/>
              &lt;/head&gt;<br/>
              &lt;body&gt;<br/>
              &lt;h2&gt;1.電子書籍作成指南&lt;/h2&gt;<br/>
              &lt;h3&gt;1-1.電子書籍とは&lt;/h3&gt;<br/>
              &lt;p&gt;紙やインクを利用した印刷物ではなく、電磁的に記録された書籍です。&lt;/p&gt;<br/>
              &lt;/body&gt;<br/>
              &lt;/html&gt;<br/>
              <br/>
              </p>
              <p className="line">HTMLさえ覚えていれば非常に簡単に本文を作成することができます。</p>
              <p className="line2">■目次</p>
              <p className="line">本文の作成が終わったら目次を作成します。<br/>Sigilには目次作成用のツールが存在しますので、それを利用すると簡単に作成することができます。<br/>「ツール→目次→目次を生成」を選択します。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/2-1-4.png`}/>
              <p className="line">目次を確認したらOKボタンをクリックします。<br/>すると画面右側の目次ウィンドウに設定された目次が表示されます。<br/>実際に目次が書かれているファイルは「nav.xhtml」です。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/2-1-5.png`}/>
              <p className="line">次に目次を本の最初に表示されるようにします。<br/>画面左側のブックブラウザーウィンドウからnav.xhtmlを先頭に移動させることで実現できます。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/2-1-6.png`}/>
              <p className="line">最後に保存を行えばEPUB化が完了します。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/2-1-7.png`}/>
            </section>
            <section id='2-2'>
              <h3>２−２．書いた文章を確認してみよう～Sigil編～</h3>
              <p className="line">２－１で作成したEPUBを確認する方法です。<br/>ここではSigilでの確認方法の説明です。</p>
              <p className="line">Sigilでの確認は非常に簡単で「表示→ビューの状態を切り替え」をクリック、またはF2キーを押します。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/2-2-1.png`}/>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/2-2-2.png`}/>
              <p className="line">これでレイアウトの確認が可能です。<br/>あくまでSigilでの確認はHTMLとしての確認となるので文法の確認程度にとどめておきましょう。</p>
            </section>
            <section id='2-3'>
              <h3>２−３．書いた文章を確認してみよう～KindlePreviewer編～</h3>
              <p className="line">KindlePreviewerを利用して全体のレイアウト確認を行います。<br/>まずはツールのダウンロードを「<a href="https://www.amazon.co.jp/gp/feature.html?docId=3077677546" target="_blank" rel="noreferrer noopener">https://www.amazon.co.jp/gp/feature.html?docId=3077677546</a>」から行います。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/2-3-1.png`}/>
              <p className="line">ダウンロードしたインストーラを起動します。<br/>基本的に設定を変更する必要はないので、そのままインストールボタンをクリックして完了させます。</p>
              <p className="line">インストールが完了したらKindlePreviewerを起動します。<br/>起動後に本を開いてリンクをクリックし、先ほど保存しておいたEPUBファイルを指定します。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/2-3-2.png`}/>
              <p className="line">開いた際に以下のダイアログが表示されればOKです。<br/>警告がでていますが今は無視して問題ないです。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/2-3-3.png`}/>
              <p className="line">これでKindlePreviewerによる確認が可能となります。<br/>ここからはSigilで修正→KindlePreviewerで確認ということを繰り返してEPUBを完成させます。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/2-3-4.png`}/>
            </section>
            <section id='2-4'>
              <h3>２−４．注意点</h3>
      				<p className="line">ここでは電子書籍を作成する上で気を付けた方がいいところをまとめて紹介します。</p>
      				<p className="line2">■半角スペースに気を付ける</p>
      				<p className="line">半角スペースは&amp;nbsp;ではなく&amp;#160;を使用しましょう。<br/>EPUBのフォーマットチェックをする際にエラーとなってしまいます。</p>
      				<p className="line2">■preタグについて</p>
      				<p className="line">ソースコードなどを記載する時によく使われるpreタグですが、Kindleで公開するとiOS関連の端末が対象から外れてしまう現象があります。<br/>これを回避するにはpreタグを使わずに自分でエスケープを行っていくしかありません。</p>
      				<p className="line2">■EPUBフォーマットチェック</p>
      				<p className="line">電子書籍を公開する前に必ず行います。「<a href="http://ck.densholab.jp/ec/" target="_blank" rel="noreferrer noopener">電子ラボチェッカー</a>」などでチェックを行いましょう。</p>
            </section>
            <h2>３．電子書籍を出版してみよう</h2>
            <section id='3-1'>
              <h3>３−１．アカウント作成</h3>
              <p className="line">電子書籍を販売するために、まずAmazon Kindle ダイレクトパブリッシング（以下KDP）のアカウント作成が必要となります。<br/>まずは「<a href="https://kdp.amazon.co.jp/ja_JP/" target="_blank" rel="noreferrer noopener">https://kdp.amazon.co.jp/ja_JP/</a>」へアクセスし、サインアップボタンをクリックします。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/3-1-1.png`}/>
              <p className="line">Eメールを入力し、「初めて利用します。」にチェックをいれてサインインボタンをクリックします。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/3-1-2.png`}/>
              <p className="line">すべての項目を入力し、アカウント作成ボタンをクリックします。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/3-1-3.png`}/>
              <p className="line">同意するボタンをクリックします。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/3-1-4.png`}/>
              <p className="line">これでKDPのアカウント作成完了です。<br/>この先はアカウント情報ををしっかりと入力するのを忘れないようにしてください。</p>
            </section>
            <section id='3-2'>
              <h3>３−２．出版</h3>
              <p className="line">ここからは電子書籍を出版するまでの説明をします。<br/>「<a href="https://kdp.amazon.co.jp/ja_JP/" target="_blank" rel="noreferrer noopener">https://kdp.amazon.co.jp/ja_JP/</a>」へアクセスしサインインボタンをクリックしログインします。</p>
              <p className="line">新しい本を作成リンクをクリックします。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/3-1-5.png`}/>
              <p className="line">本の詳細を入力します。<br/>（オプション）と書いている項目以外はすべて必須となります。<br/>特に重要な項目は「出版に関して必要な権利」でAmazonでのみ販売する場合は迷わず「私は著作権者であり、出版に関して必要な権利を保有しています。」を選びましょう。<br/>入力が完了したら保存して続行ボタンをクリックします。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/3-2-1.png`}/>
              <p className="line">本のコンテンツを設定します。<br/>ここでは実際にEPUBのアップロードと表紙のアップロードを行います。<br/>表紙はJPGなどで作りましょう。<br/>完了したら保存して続行ボタンをクリックします。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/3-2-2.png`}/>
              <p className="line">本の価格設定をします。<br/>出版したい地域、希望小売価格などを入力してKindle本を出版ボタンをクリックします。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/epub/3-2-3.png`}/>
              <p className="line">これで出版手続き完了となります。<br/>あとはKDPからの出版完了連絡を待つだけです。</p>
            </section>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="ad">
            広告エリア2
          </div>
        </Grid>
      </Grid>
    </div>
    </Zoom>
  );
}
