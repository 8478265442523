import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ScrollToTopOnMount from '../Common/ScrollToTopOnMount';
import Zoom from '@material-ui/core/Zoom';
import Grid from '@material-ui/core/Grid';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import '../Common/Common.css';
import '../Common/Moeropro.css';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function Html5() {

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);

  const handleClick1 = () => {
    setOpen1(!open1);
  };
  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const handleClick3 = () => {
    setOpen3(!open3);
  };
  const handleClick4 = () => {
    setOpen4(!open4);
  };
  const handleClick5 = () => {
    setOpen5(!open5);
  };

  return (
    <Zoom in={true}>
    <div>
      <ScrollToTopOnMount />
      <Typography variant='h6' className='Sub-title' gutterBottom>
        <Box fontWeight='fontWeightBold'>HTML5</Box>
      </Typography>
      <Grid container>
        <Grid item xs={12} md={12}>
          <List component="nav" aria-labelledby="nested-list-subheader" className="root">
            <ListItem button onClick={handleClick1}>
              <ListItemText primary="１．HTMLについて" />
              {open1 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <AnchorLink href='#1-1' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="１−１．HTMLとは" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#1-2' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="１−２．タグとは" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#1-3' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="１−３．属性とは" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#1-4' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="１−４．タグを書く時のルール" />
                  </ListItem>
                </AnchorLink>
              </List>
            </Collapse>
            <ListItem button onClick={handleClick2}>
              <ListItemText primary="２．HTMLを書いてみよう" />
              {open2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <AnchorLink href='#2-1' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="２−１．何を使って書くか" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#2-2' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="２−２．どうやって確認するか" />
                  </ListItem>
                </AnchorLink>
              </List>
            </Collapse>
            <ListItem button onClick={handleClick3}>
              <ListItemText primary="３．HTMLタグを覚えよう" />
              {open3 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open3} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <AnchorLink href='#3-1' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−１．DOCTYPE宣言" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-2' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−２．htmlタグ" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-3' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−３．headタグ、titleタグ、metaタグ" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-4' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−４．bodyタグ" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-5' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−５．h1～h6タグ" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-6' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−６．aタグ" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-7' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−７．brタグ" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-8' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−８．pタグ" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-9' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−９．tableタグ、trタグ、tdタグ" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-10' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−１０．imgタグ" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-11' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−１１．buttonタグ" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-12' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−１２．inputタグ" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-13' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−１３．selectタグ、optionタグ" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-14' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−１４．olタグ、ulタグ、liタグ" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-15' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−１５．strongタグ" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#3-16' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="３−１６．rubyタグ、rtタグ、rpタグ" />
                  </ListItem>
                </AnchorLink>
              </List>
            </Collapse>
            <ListItem button onClick={handleClick4}>
              <ListItemText primary="４．スタイルシートに触れてみよう" />
              {open4 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open4} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <AnchorLink href='#4-1' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="４−１．スタイルシートとCSS" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#4-2' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="４−２．CSSの書き方" />
                  </ListItem>
                </AnchorLink>
              </List>
            </Collapse>
            <ListItem button onClick={handleClick5}>
              <ListItemText primary="５．まとめ" />
              {open5 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open5} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <AnchorLink href='#5-1' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="５−１．自己紹介ページを作ってみよう" />
                  </ListItem>
                </AnchorLink>
              </List>
            </Collapse>
          </List>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="ad">
            広告エリア１
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <div className="main_content">
            <h2>１．HTMLについて</h2>
            <section id='1-1'>
              <h3>１−１．HTMLとは</h3>
              <p className="line">HTMLとは、Webサイトを作るためのプログラミング言語です。正式名称は、<span className="emphasis">Hyper Text Markup Language</span>（ハイパーテキスト・マークアップ・ランゲージ）と言います。<br/>
              何だかヤヤコシく感じるかもしれませんが、あまり難しく捉えなくて大丈夫です。<br/>
              また、「プログラミング言語」と言うととても難しいものを想像してしまうかもしれませんが、そちらも安心してください。<br/>
              HTMLは、ゴリゴリとプログラムを書くような使い方ではなく、文章を装飾して行くような使い方をするので、いわゆる「理系的な考え方」「ロジカルシンキング」的な要素は必須ではありません。<br/>
              要するに、「<span className="emphasis">誰でも覚えられる</span>」と言うことです。</p>
              <p className="line">現在、インターネット上に存在するWebサイトのほとんどは、このHTMLで作られています。<br/>
              と、言うことは、HTMLを覚えればあなたもWebサイトが作れるようになると言うわけですね。<br/>
              そう考えると、ワクワクして来ませんか？</p>
              <p className="line">ちなみに、2016年5月現在でのHTMLの最新バージョンは「<span className="emphasis">HTML5</span>」と呼ばれるモノになります。<br/>
              以降、このサイトで「<span className="emphasis">HTML</span>」と書いてある箇所は、全て「<span className="emphasis">HTML5</span>」を指しているものと考えてください。</p>
            </section>
            <section id='1-2'>
              <h3>１−２．タグとは</h3>
  				    <p className="line">HTMLは、<span className="emphasis">タグ</span>と言うモノを使って書いて行きます。<br/>
  				    タグとは、「<span className="emphasis">&lt;</span>」と「<span className="emphasis">&gt;</span>」で囲った文字列で、「<span className="emphasis">&lt;html&gt;</span>」「<span className="emphasis">&lt;h1&gt;</span>」こんな感じの書き方をします。<br/>
  				    また、一部を除いて、タグは<span className="emphasis">開始タグ</span>と<span className="emphasis">終了タグ</span>と呼ばれるモノで値を囲むルールになっています。<br/>
  				    開始タグとは、先ほど説明したタグのことで、終了タグとは、<span className="emphasis">タグ名の前にスラッシュ（/）を付けたタグ</span>です。<br/>
  				    これらをまとめると、以下のようになります。</p>
  				    <p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
  				    <p className="sample">
              <br/>
  				    <span className="dec">&lt;h1&gt;</span>タイトル<span className="dec">&lt;/h1&gt;</span><br/>
  				    <br/>
  				    </p>
  				    <p className="line">これがタグの基本的な書き方です。<br/>
  				    HTMLにおける<span className="emphasis">基礎中の基礎</span>なので、必ずここでこのルールを覚えるようにしてくださいね。</p>
  				    <p className="line"><span className="emphasis">HTMLを覚えると言うことは、タグを覚えること</span>です。<br/>
  				    どんなタグがあって、そのタグはどんな意味を持っているのか、それを覚えて行きます。<br/>
  				    たくさんの種類があるので、慣れるまでは大変かもしれませんが、色々と使っていくうちに勝手に頭に入っていくと思います。<br/>
  				    と言うのも、タグの名前には、ある程度<span className="emphasis">その名前から意味が推測出来る</span>ようになっているからです。</p>
  				    <p className="line">ちなみに、HTMLのタグについての情報や解説は、インターネットで検索すれば大量に見付かります。<br/>
  				    分からなくなったらすぐに調べるクセを付けておきましょう。</p>
            </section>
            <section id='1-3'>
              <h3>１−３．属性とは</h3>
  				    <p className="line">属性とは、<span className="emphasis">タグに対して細かな説明を加える</span>ために使用します。<br/>
  				    例えば、タグのID（名前みたいなモノ）を指定したりします。<br/>
  				    基本的な書き方は以下の通りです。</p>
  				    <p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
  				    <p className="sample">
  				    <br/>
  				    <span className="dec">&lt;div</span> <span className="attr">id</span>="test"<span className="dec">&gt;</span><br/>
  				    <br/>
  				    </p>
  				    <p className="line">例のように、属性名の後ろに<span className="emphasis">イコールを書いて、その後ろにダブルクォーテーション（&quot;）で属性の値を囲う</span>ルールになっています。<br/>
  				    また、タグ名と属性名の間には、<span className="emphasis">必ず半角のスペースを入れる</span>ことを忘れないようにしましょう。</p>
  				    <p className="line">なお、属性は一つだけではなく、複数個を指定することが出来ます。<br/>
  				    この場合は、属性の指定ごとに半角のスペースで区切ればOKです。</p>
  				    <p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
  				    <p className="sample">
  				    <br/>
  				    <span className="dec">&lt;div</span> <span className="attr">id</span>="test" <span className="attr">class</span>="hoge"<span className="dec">&gt;</span><br/>
  				    <br/>
  				    </p>
  				    <p className="line">この例では、<span className="emphasis">div</span>と言うタグに<span className="emphasis">id</span>と言う属性と<span className="emphasis">class</span>と言う属性を同時に指定しています。</p>
            </section>
            <section id='1-4'>
              <h3>１−４．タグを書く時のルール</h3>
  				    <p className="line">タグは、いくつものタグを「入れ子」状にして書くことが出来ます。<br/>
  				    ただし、入れ子をまたいでタグを記述することは出来ません。<br/>
  				    具体的には以下の例を見てください。</p>
  				    <p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
  				    <p className="sample">
  				    <br/>
      				<strong>正しい</strong><br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				<span className="dec">&lt;p&gt;&lt;em&gt;</span>文字列<span className="dec">&lt;/em&gt;&lt;/p&gt;</span><br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<br/>
      				<strong>間違い</strong><br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				<span className="dec">&lt;p&gt;&lt;em&gt;</span>文字列<span className="attr">&lt;/p&gt;</span><span className="dec">&lt;/em&gt;</span>  ←pタグの終了タグの位置が間違っている<br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<br/>
      				</p>
      				<p className="line">さて、いろいろと説明して来ましたけど、とりあえず<span className="emphasis">ルールはここまで</span>です！<br/>
      				お疲れ様でした。<br/>
      				最後に、ザッとルールのおさらいをして、次のステップに進みましょう。<br/><br/>
      				<span className="indent2em">・HTMLとは、タグを使って書くものである</span><br/>
      				<span className="indent2em">・タグは、基本的に開始タグと終了タグのセットで書くものである</span><br/>
      				<span className="indent2em">・属性を使うと、タグに細かい説明を付けることが出来る</span><br/>
      				<span className="indent2em">・タグは入れ子状にして書けるが、入れ子を跨いで書くことは出来ない</span></p>
            </section>
            <h2>２．HTMLを書いてみよう</h2>
            <section id='2-1'>
  				    <h3>２−１．何を使って書くか</h3>
  				    <p className="line">さて、ここからは実際にHTMLを書くための説明を進めて行きます。<br/>
      				HTMLを書くためには、何か特別な環境を用意する必要は無く、WindowsでもMacでも何らかのパソコンがあればOKです。（Linuxはここでは対象外とします）</p>
      				<p className="line">まず、Windowsを使っている人向けの説明から始めます。<br/>
      				Windowsには、標準で「<span className="emphasis">メモ帳</span>」と言うテキストエディタがインストールされているので、最初のうちはこれでも十分です。<br/>
      				しかし、慣れて来たら、是非他の高性能なエディタを試してみてください。<br/>
      				例えば、サクラエディタ（http://sakura-editor.sourceforge.net/）などは、開発現場のプロ達でも好んで使う人が多いぐらい、高性能なエディタです。<br/>
      				しかも無料で使えるので、インストールに当って何も躊躇する必要は無いと思います。</p>
      				<p className="line">次に、Macを使っている人ですが、こちらはWindowsとは違い、標準でインストールされている「テキストエディット」は使わない方が無難です。<br/>
      				思いのほか多機能で、慣れないうちはそれが逆にアダとなるケースがあるからです。<br/>
      				ですので、筆者的には<span className="emphasis">CotEditor</span>（https://coteditor.com/）をオススメいたします。<br/>
      				日本製のエディタで、シンプルで非常に使いやすいエディタです。<br/>
      				こちらも、もちろん無料で使えます。</p>
      				<p className="line">最後に、WindowsもMacも共通して注意して欲しいのが、作成したファイルの保存方法です。<br/>
      				HTMLファイルは、文字コードを「<span className="emphasis">UTF-8</span>」って言うモノにするのが一般的です。<br/>
      				なお、Macの場合はあえて変更しなければUTF-8として保存されるのですが、<span className="emphasis">Windowsの場合は要注意</span>です。<br/>
      				Windowsは、標準で使用する文字コードがShift-JISと言うモノであるため、基本的に自分で変更しない限りUTF-8としては保存してくれません。<br/>
      				上で紹介したメモ帳にしろサクラエディタにしろ、ファイルを保存するダイアログに「<span className="emphasis">文字コードを選択するプルダウン</span>」があるので、必ずそこでUTF-8を選択するようにしてください。<br/>
      				※UTF-8にしなければならない理由は、今は気にしなくて大丈夫です</p>
            </section>
            <section id='2-2'>
              <h3>２−２．どうやって確認するか</h3>
      				<p className="line">次に、自分で書いたHTMLを、どうやって確認するか説明します。<br/>
      				自分で書いたHTMLが、Webサイトとしてどんな風に見えるのかを確認すると言うことです。<br/>
      				ちなみに、こちらも何か特別な環境を用意する必要はありません。</p>
      				<p className="line">まず、HTMLとは、<span className="emphasis">エディタで書いてブラウザで確認する</span>モノだと覚えてください。<br/>
      				ブラウザとは、Windowsで言うところのInternetExplorerや、Macで言うところのSafariなどの、Webサイトを見るためのアプリケーションのことです。<br/>
      				みなさん日頃から使っていますよね？</p>
      				<p className="line">そして、確認方法としては、作成した<span className="emphasis">HTMLファイルをダブルクリックするだけ</span>です。<br/>
      				WindowsにしろMacにしろ、ファイルの関連付けを変更していなければ、この時点でブラウザにHTMLファイルの内容が表示されます。<br/>
      				非常に簡単ですね。<br/>
      				次からいよいよタグの勉強に入って行きますので、是非自分でHTMLを書いてみて、ブラウザに表示させて確認してみてください。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）このようなHTMLを書くと</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				<span className="dec">&lt;head&gt;</span><br/>
      				<span className="dec">&#160;&#160;&#160;&#160;&lt;meta</span> <span className="attr">charset</span>="UTF-8"&gt;<br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;title&gt;</span>サンプル<span className="dec">&lt;/title&gt;</span><br/>
      				<span className="dec">&lt;/head&gt;</span><br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;h1&gt;</span>これはHTMLです<span className="dec">&lt;/h1&gt;</span><br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）ブラウザではこんな感じに見えます</span></p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/2-2.png`}/>
            </section>
            <h2>３．HTMLタグを覚えよう</h2>
            <section id='3-1'>
      				<h3>３−１．DOCTYPE宣言</h3>
      				<p className="line">さて、「タグを覚えよう」と言っておきながらも、一発目はタグとは若干毛色の異なるモノからの説明となります。<br/>
      				とは言え、この<span className="emphasis">DOCTYPE宣言</span>は、HTMLにとって非常に重要な存在です。<br/>
      				必ず覚えるようにしてください。</p>
      				<p className="line">DOCTYPE宣言とは、「<span className="emphasis">このファイルはHTMLで書かれているよ！</span>」ってブラウザに知らせるためのモノです。<br/>
      				とりあえず、<span className="emphasis">HTMLを書く場合は必ずこれを先頭に書く</span>と言うことを忘れないでください。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="attr">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				&#160;&#160;&#160;&#160;〜〜<br/>
      				&#160;&#160;&#160;&#160;略<br/>
      				&#160;&#160;&#160;&#160;〜〜<br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<p className="line">例の太字の部分がDOCTYPE宣言です。<br/>
      				「!」の後ろに「<span className="emphasis">DOCTYPE</span>」と続けて書いて、半角スペースを一つ挟んで最後に「<span className="emphasis">html</span>」と書きます。<br/>
      				もうこれは<span className="emphasis">HTMLのお作法</span>みたいなモノなので、「こい言うルールなんだ」って捉えておいてください。</p>
            </section>
            <section id='3-2'>
              <h3>３−２．htmlタグ</h3>
      				<p className="line">htmlタグは、DOCTYPE宣言と同じく、HTMLファイルの中に<span className="emphasis">必ず書く</span>タグになります。<br/>
      				<span className="emphasis">DOCTYPE宣言を書いたら、次はHTMLタグ</span>と覚えておきましょう。<br/>
      				なお、HTMLファイルの末端はこれの終了タグを書くことになります。<br/>
      				要するに、<span className="emphasis">DOCTYPE宣言以降は、全てこのHTMLファイルタグで囲う</span>と言うことです。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="attr">&lt;html&gt;</span><br/>
      				&#160;&#160;&#160;&#160;〜〜<br/>
      				&#160;&#160;&#160;&#160;略<br/>
      				&#160;&#160;&#160;&#160;〜〜<br/>
      				<span className="attr">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<p className="line">なお、htmlタグには<span className="emphasis">lang</span>と言う属性が存在します。<br/>
      				この属性は、HTMLが<span className="emphasis">何語で作られているか</span>を指定するためのモノです。<br/>
      				恐らく、このサイトの読者さんは全員日本人だと思いますし、作りたいWebサイトも日本語を扱うでしょうから、以下のように指定しておけばOKです。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;html</span> <span className="attr">lang</span>="ja"<span className="dec">&gt;</span><br/>
      				<br/>
      				</p>
            </section>
            <section id='3-3'>
              <h3>３−３．headタグ、titleタグ、metaタグ</h3>
      				<p className="line">headタグも、HTMLファイルの中に<span className="emphasis">必ず書く</span>タグです。<br/>
      				htmlタグの次に書くルールとなっており、このタグには<span className="emphasis">HTMLファイルの説明</span>を加えて行きます。<br/>
      				なお、「ファイルの説明」には色々なモノがあるのですが、とりあえず今は以下の二つを覚えておきましょう。<br/>
      				<span className="indent2em">・タイトル</span><br/>
      				<span className="indent2em">・文字コード</span></p>
      				<p className="line">まずタイトルですが、その名の通り<span className="emphasis">titleタグ</span>を使って指定します。<br/>
      				このタグで指定した値は、<span className="emphasis">ブラウザのタイトルバーやブックマークした時の名前、検索エンジンでの結果表示</span>の際に使われます。<br/>
      				次に文字コードですが、これは<span className="emphasis">metaタグ</span>を使って指定します。<br/>
      				metaタグには、他にも様々な役割りがあるのですが、今は後回しにします。</p>
      				<p className="line">では、ここで説明した３つのタグを、一気に例で確認してみましょう。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				<span className="attr">&lt;head&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;meta charset="UTF-8"&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;title&gt;サンプル&lt;/title&gt;</span><br/>
      				<span className="attr">&lt;/head&gt;</span><br/>
      				〜〜<br/>
      				略<br/>
      				〜〜<br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<p className="line">まず、headタグとmetaタグ（文字コードの指定）は、例によってお約束だと思ってください。<br/>
      				常にこの記述でOKです。<br/>
      				また、titleタグには、その都度好きな値を入れればOKです。</p>
            </section>
            <section id='3-4'>
              <h3>３−４．bodyタグ</h3>
      				<p className="line">次はbodyタグです。<br/>
      				ちなみに、このタグもHTMLファイルの中に<span className="emphasis">必ず書く</span>タグなのですが、この「<span className="emphasis">必ず書くシリーズ</span>」はこれで最後です。</p>
      				<p className="line">body、直訳すると「<span className="emphasis">体</span>」ですね。<br/>
      				その名の通り、bodyタグは<span className="emphasis">HTMLファイルの本体を構成</span>します。<br/>
      				なお、HTMLファイルの本体とは、簡単に言えば<span className="emphasis">ブラウザに表示される部分</span>です。<br/>
      				文章や表、グラフや映像など、ブラウザ上に表示するアイテム（タグ）は、全てこのbodyタグの中に含めることになります。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				〜〜<br/>
      				略<br/>
      				〜〜<br/>
      				<span className="attr">&lt;body&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;h1&gt;</span>これはHTMLです<span className="dec">&lt;/h1&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;p&gt;</span>HTMLは楽しいですか？<span className="dec">&lt;/p&gt;</span><br/>
      				<span className="attr">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
            </section>
            <section id='3-5'>
              <h3 id="sigil_toc_id_11">３−５．h1～h6タグ</h3>
      				<p className="line"><span className="emphasis">h1タグ〜h6タグ</span>は、<span className="emphasis">見出し</span>を付けるために使用します。<br/>
      				見出しの種類に応じて、「h」の後ろに1〜6の数値を指定します。（1が一番大きい見出しになります）<br/>
      				なお、「h」は英語で「見出し」や「表題」を意味する「<span className="emphasis">heading</span>」の略です。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				〜〜<br/>
      				略<br/>
      				〜〜<br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;h1&gt;見出し１&lt;/h1&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;h2&gt;見出し２&lt;/h2&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;h3&gt;見出し３&lt;/h3&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;h4&gt;見出し４&lt;/h4&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;h5&gt;見出し５&lt;/h5&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;h6&gt;見出し６&lt;/h6&gt;</span><br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/3-5.png`}/>
            </section>
            <section id='3-6'>
              <h3>３−６．aタグ</h3>
      				<p className="line"><span className="emphasis">aタグ</span>は、<span className="emphasis">ハイパーリンク</span>を用意するために使用します。<br/>
      				ハイパーリンクとは、簡単に言ってしまえば「<span className="emphasis">別のページ（HTMLなど）へのリンク</span>」です。<br/>
      				例えば、ニュースサイトで、<span className="emphasis">記事見出しをクリックすると、その内容のページに移動</span>しますよね？<br/>
      				この場合、「<span className="emphasis">記事の見出し</span>」がハイパーリンクです。</p>
      				<p className="line">aタグには色々な属性が存在しますが、とりあえずは「<span className="emphasis">href属性</span>」について覚えておきましょう。<br/>
      				href属性は、リンク先を指定するために使用します。<br/>
      				例えば、リンク先を「https://www.google.co.jp/」にする場合は、以下のように記述します。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				〜〜<br/>
      				略<br/>
      				〜〜<br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;a href="https://www.google.co.jp/"&gt;リンク&lt;/a&gt;</span><br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/3-6-1.png`}/>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">↓リンクをクリックすると</span></p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/3-6-2.png`}/>
      				<p className="line">なお、aタグの「a」は錨を意味する<span className="emphasis">アンカー</span>（anchor）の頭文字で、hrefは「<span className="emphasis">hyper reference</span>」の略語になります。</p>
            </section>
            <section id='3-7'>
              <h3>３−７．brタグ</h3>
      				<p className="line"><span className="emphasis">brタグ</span>は、任意の場所で<span className="emphasis">改行</span>するために使用します。<br/>
      				由来は「<span className="emphasis">line break</span>」です。</p>
      				<p className="line">HTMLでは、エディタでどんなに改行を使っても、実際にブラウザでみると改行されていません。<br/>
      				なので、思った通りに改行させたい場合は、brタグを使う必要があります。<br/>
      				使い方は簡単で、改行したい位置にタグを書くだけです。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				〜〜<br/>
      				略<br/>
      				〜〜<br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				&#160;&#160;&#160;&#160;この文章は<br/>
      				&#160;&#160;&#160;&#160;改行されません<br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;br&gt;</span><br/>
      				&#160;&#160;&#160;&#160;この文章は<span className="attr">&lt;br&gt;</span>改行されます<br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/3-7.png`}/>
            </section>
            <section id='3-8'>
              <h3>３−８．pタグ</h3>
      				<p className="line"><span className="emphasis">pタグ</span>は、一つの<span className="emphasis">段落</span>を表すために使用します。<br/>
      				「p」は英語で段落を意味する「<span className="emphasis">パラグラフ（Paragraph）</span>」の頭文字です。<br/>
      				言葉の通り、ひとまとめにしたい文章（段落分けしたい文章）は、このタグでまとめるといいでしょう。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				〜〜<br/>
      				略<br/>
      				〜〜<br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;h1&gt;</span>オススメの居酒屋をご紹介！<span className="dec">&lt;/h1&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;p&gt;xxx市xxx駅目の前にあるXXXって店がオススメ！値段がリーズナブルなのに、料理の味は抜群！！是非みんな行ってみて！&lt;/p&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;h2&gt;</span>アクセス<span className="dec">&lt;/h2&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;p&gt;JR〜〜駅改札を出て、信号を渡って目の前&lt;/p&gt;</span><br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/3-8.png`}/>
            </section>
            <section id='3-9'>
              <h3>３−９．tableタグ、trタグ、tdタグ</h3>
      				<p className="line"><span className="emphasis">tableタグ</span>は、その名の通り<span className="emphasis">テーブル（表）を作成するため</span>に使用します。<br/>
      				また、<span className="emphasis">trタグ</span>は<span className="emphasis">テーブルの行</span>を、<span className="emphasis">tdタグ</span>は<span className="emphasis">行の中のセル</span>を作成します。<br/>
      				※テーブルを構成するためのタグは他にもいくつかあるのですが、とりあえず最低限この３つを抑えておけば大丈夫です<br/>
      				なお、HTMLでキレイなテーブルを作ろうとすると、結構色々な知識やテクニックが必要になって来ますので、まずは基本的な使い方を覚えてもらえればと思います。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				〜〜<br/>
      				略<br/>
      				〜〜<br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;table&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;caption&gt;おしながき&lt;/caption&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;td&gt;生ビール&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;td&gt;３５０円&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;/tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;td&gt;ハイボール&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;td&gt;４００円&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;/tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;td&gt;えだまめ&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;td&gt;１５０円&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;/tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;/table&gt;</span><br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/3-9.png`}/>
      				<p className="line">例で使用した<span className="emphasis">captionタグ</span>は、テーブルに<span className="emphasis">タイトルを付ける</span>ために使用します。<br/>
      				余裕があれば、こちらも覚えておいてください。</p>
            </section>
            <section id='3-10'>
              <h3>３−１０．imgタグ</h3>
      				<p className="line"><span className="emphasis">imgタグ</span>は、<span className="emphasis">画像を挿入</span>する場合に使用します。
      				由来は、英語で画像を意味する「image」から来ています。</p>
      				<p className="line">imgタグで画像を表示させるには、最低限以下の３つの属性を抑えておく必要があります。<br/>
      				<span className="indent2em">・src属性</span><br/>
      				<span className="indent2em">・width属性</span><br/>
      				<span className="indent2em">・height属性</span><br/>
      				まず、src属性には、使用する画像ファイルを指定します。<br/>
      				次に、width属性には画像の横幅を、height属性には画像の縦幅を指定します。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				〜〜<br/>
      				略<br/>
      				〜〜<br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;img src="./title.png" width="160" height="256"&gt;</span><br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/3-10.png`}/>
      				<p className="line">ちなみに、<span className="emphasis">imgタグには終了タグが存在しない</span>ので、注意してください。</p>
            </section>
            <section id='3-11'>
              <h3>３−１１．buttonタグ</h3>
      				<p className="line"><span className="emphasis">buttonタグ</span>は、名前の通り<span className="emphasis">ボタンを用意する</span>ためのタグです。<br/>
      				このタグには様々な属性が存在しますが、とりあえず<span className="emphasis">type属性</span>を覚えておきましょう。<br/>
      				type属性には、用意するボタンが「<span className="emphasis">どんな種類なのか</span>」を指定します。<br/>
      				また、ボタンの種類には以下の３つが存在します。<br/>
      				<span className="indent2em">・submit&#160;&#160;画面の内容を送信するためのボタン</span><br/>
      				<span className="indent2em">・reset&#160;&#160;&#160;&#160;&#160;画面の内容をリセットするためのボタン</span><br/>
      				<span className="indent2em">・button&#160;&#160;汎用的に使うボタン</span></p>
      				<p className="line">なお、ボタンを使って何らかの「<span className="emphasis">仕掛け</span>」を作ろうとする場合、<span className="emphasis">HTML以外にもプログラミングの知識が必要</span>になります。
      				ですので、今のところはボタンの配置方法だけを覚えておき、いずれもっと詳しい使い方を学ぶようにしましょう。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				〜〜<br/>
      				略<br/>
      				〜〜<br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;button type="button"&gt;ボタン&lt;/button&gt;</span><br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/3-11.png`}/>
            </section>
            <section id='3-12'>
              <h3>３−１２．inputタグ</h3>
      				<p className="line"><span className="emphasis">inputタグ</span>は、<span className="emphasis">入力欄や選択欄を用意する</span>ために使用します。<br/>
      				buttonタグと同じく、<span className="emphasis">プログラムとセットで活躍する</span>存在なので、今のところは「こんなのがあるんだー」程度に覚えておいてもらえれば十分です。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				〜〜<br/>
      				略<br/>
      				〜〜<br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;input type="text"&gt;&lt;br&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;input type="radio" name="sake" value="生ビール"&gt;生ビール</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;input type="radio" name="sake" value="ハイボール" checked="checked"&gt;ハイボール</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;input type="radio" name="sake" value="日本酒"&gt;日本酒&lt;br&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;input type="checkbox" name="food" value="えだまめ"&gt;えだまめ</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;input type="checkbox" name="food" value="ホッケ" checked="checked"&gt;ホッケ</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;input type="checkbox" name="food" value="たこわさ" checked="checked"&gt;たこわさ</span><br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/3-12.png`}/>
            </section>
            <section id='3-13'>
              <h3>３−１３．selectタグ、optionタグ</h3>
      				<p className="line"><span className="emphasis">selectタグ</span>と<span className="emphasis">optionタグ</span>は、「<span className="emphasis">プルダウン</span>」と呼ばれる部品を用意するためのタグです。<br/>
      				これもbuttonタグやinputタグと同じく、<span className="emphasis">プログラムとセットで活躍する</span>存在なので、今のところはサラっと眺める程度に止めておきましょう。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				〜〜<br/>
      				略<br/>
      				〜〜<br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;select&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;option value="りんご"&gt;りんご&lt;/option&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;option value="バナナ"&gt;バナナ&lt;/option&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;option value="ぶどう"&gt;ぶどう&lt;/option&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;/select&gt;</span><br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/3-13.png`}/>
            </section>
            <section id='3-14'>
              <h3>３−１４．olタグ、ulタグ、liタグ</h3>
      				<p className="line"><span className="emphasis">olタグとulタグとliタグ</span>は、<span className="emphasis">リストを用意するため</span>のタグです。<br/>
      				olタグは「<span className="emphasis">順序のあるリスト</span>」、ulタグは「<span className="emphasis">順序のないリスト</span>」、liタグは「<span className="emphasis">リストの項目</span>」になります。<br/>
      				olタグについてはtype属性により<span className="emphasis">色々な種類のリストを作成</span>することができます。<br/>
      				ちなみにそれぞれのタグは「<span className="emphasis">Order List</span>」「<span className="emphasis">Unordered List</span>」「<span className="emphasis">List Item</span>」の略です。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				〜〜<br/>
      				略<br/>
      				〜〜<br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;ol type="1"&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;li&gt;報告&lt;/li&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;li&gt;連絡&lt;/li&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;li&gt;相談&lt;/li&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;/ol&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;ul&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;li&gt;報告&lt;/li&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;li&gt;連絡&lt;/li&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">&lt;li&gt;相談&lt;/li&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;/ul&gt;</span><br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/3-14.png`}/>
            </section>
            <section id='3-15'>
              <h3>３−１５．strongタグ</h3>
      				<p className="line"><span className="emphasis">strongタグ</span>は、その名のとおり<span className="emphasis">強い重要性</span>を表すために使用します。<br/>
      				ブラウザで確認するとstrongタグで囲まれた文章は<span className="emphasis">太字</span>となって表示されます。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				〜〜<br/>
      				略<br/>
      				〜〜<br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">このように&lt;strong&gt;重要な箇所&lt;/strong&gt;を表します。</span><br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/3-15.png`}/>
            </section>
            <section id='3-16'>
              <h3>３−１６．rubyタグ、rtタグ、rpタグ</h3>
      				<p className="line"><span className="emphasis">rubyタグとrtタグとrpタグ</span>は、<span className="emphasis">ルビをふる</span>時に使用します。<br/>
      				使い方はrubyタグの子要素としてrtタグ「<span className="emphasis">ルビのテキスト</span>」とrpタグ「<span className="emphasis">ルビのテキストを囲む記号など</span>」を配置して使用します。<br/>
      				またrpタグはrubyタグが<span className="emphasis">対応されていないブラウザ</span>の場合に利用されます。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html&gt;</span><br/>
      				〜〜<br/>
      				略<br/>
      				〜〜<br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;ruby&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">檸檬&lt;rp&gt;(&lt;/rp&gt;&lt;rt&gt;れもん&lt;/rt&gt;&lt;rp&gt;)&lt;/rp&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;/ruby&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;br&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;ruby&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="attr">薔薇&lt;rp&gt;(&lt;/rp&gt;&lt;rt&gt;ばら&lt;/rt&gt;&lt;rp&gt;)&lt;/rp&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="attr">&lt;/ruby&gt;</span><br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/3-16.png`}/>
            </section>
            <h2>４．スタイルシートに触れてみよう</h2>
            <section id='4-1'>
      				<h3>４−１．スタイルシートとCSS</h3>
      				<p className="line">さて、主要なタグを学んだ後は、<span className="emphasis">スタイルシート</span>というモノに少し触れておきましょう。<br/>
      				スタイルシートとは、HTMLで作成した<span className="emphasis">Webサイトを装飾する</span>ための仕組みです。<br/>
      				HTMLでは、<span className="emphasis">CSS</span>と呼ばれるスタイルシート言語を使って、様々な装飾を行います。<br/>
      				なお、CSSとは、Cascading Style Sheets（カスケーディング・スタイル・シート）の略です。</p>
      				<p className="line">CSSは、根本的にHTMLとは別物です。<br/>
      				また、非常に奥が深く、使いこなすにはそれなりの修練が必要な言語です。<br/>
      				ですので、このサイトではCSSの存在とその役割りについて軽く触れてもらい、今後必要に応じて勉強を進めてもらえればと思います。</p>
            </section>
            <section id='4-2'>
              <h3>４−２．CSSの書き方</h3>
      				<p className="line">CSSをHTMLに適用させる方法として、以下の三種類があります。<br/>
      				<span className="indent2em">①外部ファイルとしてcssファイルを用意し、HTMLに読み込ませる</span><br/>
      				<span className="indent2em">②styleタグにCSSを定義する</span><br/>
      				<span className="indent2em">③装飾したいタグのstyle属性にCSSを定義する</span><br/>
      				一般的に推奨されているのは①か②なのですが、今回は直感的に一番理解しやすい③で説明を進めて行きたいと思います。</p>
      				<p className="line">まず、CSSの書き方の基本として、「<span className="emphasis">何に</span>」「<span className="emphasis">どんなスタイルを適用するか</span>」と言う書き方をすることを覚えてください。<br/>
      				例えば、とあるpタグの文字色を赤にする場合は、以下のように記述します。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;p</span> <span className="attr">style="color: red;"</span><span className="dec">&gt;</span>このタグの中身は赤い文字になります<span className="dec">&lt;/p&gt;</span><br/>
      				<br/>
      				</p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/4-2-1.png`}/>
      				<p className="line">また、文字を大きくするには、以下のように記述します。</p>
      				<p style={{marginBottom:'0px'}}><span className="samplenum">例）</span></p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;p</span> <span className="attr">style="font-size: x-large;"</span><span className="dec">&gt;</span>文字が大きくなります<span className="dec">&lt;/p&gt;</span><br/>
      				<br/>
      				</p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/4-2-2.png`}/>
      				<p className="line">いかがでしょうか。<br/>
      				CSSは、他にも「<span className="emphasis">余白の設定</span>」とか「<span className="emphasis">罫線の指定</span>」や、「<span className="emphasis">アニメーション</span>」を付けたりも出来ます。<br/>
      				自分好みの装飾が出来るようになると、とたんにWebサイト作成が楽しくなりますので、HTMLに慣れてきたら是非詳しく勉強してみてください。</p>
            </section>
            <h2>５．まとめ</h2>
            <section id='5-1'>
      				<h3>５−１．自己紹介ページを作ってみよう</h3>
      				<p className="line">まとめとして、<span className="emphasis">簡単な自己紹介ページ</span>を作ってみましょう。<br/>
      				構成は以下のようにします。<br/>
      				<span className="indent2em">・h1タグを使って、タイトルを表示する</span><br/>
      				<span className="indent2em">・pタグを使って、自己紹介文を表示する</span><br/>
      				<span className="indent2em">・olタグを使って、得意な教科を表示する</span><br/>
      				<span className="indent2em">・strongタグとrubyタグを使って、座右の銘を表示する</span><br/>
      				<span className="indent2em">・tableタグを使って、簡単な経歴を表示する</span></p>
      				<p className="line">また、CSSを使って、以下のことも実現してみましょう。<br/>
      				<span className="indent2em">・タイトルの文字色を青くする</span><br/>
      				<span className="indent2em">・自己紹介文の文字を少しだけ小さくする</span><br/>
      				<span className="indent2em">・経歴のテーブルは枠線で囲ってみる</span></p>
      				<p className="line">では、早速始めましょう。<br/>
      				まずは、HTMLに無くてはならないタグを一気に用意してしまいます。<br/>
      				<span className="emphasis">DOCTYPE宣言、htmlタグ、headタグ、titleタグ、metaタグ、bodyタグ</span>でしたね。</p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html</span> <span className="attr">lang</span>="ja"<span className="dec">&gt;</span><br/>
      				<span className="dec">&lt;head&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;meta</span> <span className="attr">charset</span>="UTF-8"<span className="dec">&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;title&gt;</span>自己紹介<span className="dec">&lt;/title&gt;</span><br/>
      				<span className="dec">&lt;/head&gt;</span><br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				<br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<p className="line">こんな感じです。<br/>
      				次に、タイトル用のh1タグを用意しましょう。<br/>
      				CSSで文字色も変更します。</p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;h1</span> <span className="attr">style</span>="color:blue;"<span className="dec">&gt;</span>私のプロフィール<span className="dec">&lt;/h1&gt;</span><br/>
      				<br/>
      				</p>
      				<p className="line">これで、「私のプロフィール」という文字が青く表示されます。<br/>
      				続いて、pタグで自己紹介文を用意しましょう。<br/>
      				こちらは、CSSで少しだけ文字を小さめにします。</p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;p</span> <span className="attr">style</span>="font-size:small;"<span className="dec">&gt;</span><br/>
      				&#160;&#160;&#160;&#160;私の趣味は、読書と映画鑑賞です。週に一度は映画館に足を運んでいます。また、最近は電子書籍にハマっています。<br/>
      				<span className="dec">&lt;/p&gt;</span><br/>
      				<br/>
      				</p>
      				<p className="line">今回はタグの中身が長くなったので、開始タグと内容、終了タグをそれぞれ違う行に書いてみました。<br/>
      				HTMLは、関連するタグの情報の全てを続けて書かなければならない訳ではなく、むしろ適度に改行させて見やすくする方が一般的です。<br/>
      				続いてolタグで好きな教科を順序付けして書いてみましょう。</p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;p&gt;</span><br/>
      				&#160;&#160;&#160;&#160;好きな教科<br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;ol</span> <span className="attr">type</span>="1"<span className="dec">&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;li&gt;</span>体育<span className="dec">&lt;/li&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;li&gt;</span>日本史<span className="dec">&lt;/li&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;li&gt;</span>地学<span className="dec">&lt;/li&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;/ol&gt;</span><br/>
      				<span className="dec">&lt;/p&gt;</span><br/>
      				<br/>
      				</p>
      				<p className="line">この書き方をすることにより「体育」「日本史」「地学」の順番に好きだということが見てる人にわかりやすくなります。<br/>
      				次にstrongタグを使用して座右の銘を太字にし、rubyタグを使って座右の銘にルビを振るように書いてみましょう。</p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;p&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;strong&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;座右の銘：<br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;ruby&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;天上天下唯我独尊<span className="dec">&lt;rt&gt;</span>てんじょうてんげゆいがどくそん<span className="dec">&lt;/rt&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;/ruby&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;/strong&gt;</span><br/>
      				<span className="dec">&lt;/p&gt;</span><br/>
      				<br/>
      				</p>
      				<p className="line">これで座右の銘を強調し、ルビをふることで文字がわからなくても読めるようになりました。<br/>
      				では、最後にtableタグを使って簡単な経歴表を用意してみましょう。</p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;table</span> <span className="attr">style</span>="border: 1px solid black"<span className="dec">&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>19XX年3月<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>XX県立XX高校卒業<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>19XX年4月<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>XX大学入学<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>20XX年3月<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>XX大学卒業<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>20XX年4月<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>株式会社XX入社<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				<span className="dec">&lt;/table&gt;</span><br/>
      				<br/>
      				</p>
      				<p className="line">これで一通りの準備が終わりました。<br/>
      				あとは、用意した全ての「パーツ」を合体させて、ブラウザで確認してみましょう。</p>
      				<p className="sample">
      				<br/>
      				<span className="dec">&lt;!DOCTYPE html&gt;</span><br/>
      				<span className="dec">&lt;html</span> <span className="attr">lang</span>="ja"<span className="dec">&gt;</span><br/>
      				<span className="dec">&lt;head&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;meta</span> <span className="attr">charset</span>="UTF-8"<span className="dec">&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;title&gt;</span>自己紹介<span className="dec">&lt;/title&gt;</span><br/>
      				<span className="dec">&lt;/head&gt;</span><br/>
      				<span className="dec">&lt;body&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;h1</span> <span className="attr">style</span>="color:blue;"<span className="dec">&gt;</span>私のプロフィール<span className="dec">&lt;/h1&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;p</span> <span className="attr">style</span>="font-size:small;"<span className="dec">&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;私の趣味は、読書と映画鑑賞です。週に一度は映画館に足を運んでいます。また、最近は電子書籍にハマっています。<br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;/p&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;p&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;好きな教科<br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;ol</span> <span className="attr">type</span>="1"<span className="dec">&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;li&gt;</span>体育<span className="dec">&lt;/li&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;li&gt;</span>日本史<span className="dec">&lt;/li&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;li&gt;</span>地学<span className="dec">&lt;/li&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;/ol&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;/p&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;p&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;strong&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;座右の銘：<br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;ruby&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;天上天下唯我独尊<span className="dec">&lt;rt&gt;</span>てんじょうてんげゆいがどくそん<span className="dec">&lt;/rt&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;/ruby&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;/strong&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;/p&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;table</span> <span className="attr">style</span>="border: 1px solid black"<span className="dec">&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>19XX年3月<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>XX県立XX高校卒業<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>19XX年4月<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>XX大学入学<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>20XX年3月<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>XX大学卒業<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>20XX年4月<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;td&gt;</span>株式会社XX入社<span className="dec">&lt;/td&gt;</span><br/>
      				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span className="dec">&lt;tr&gt;</span><br/>
      				&#160;&#160;&#160;&#160;<span className="dec">&lt;/table&gt;</span><br/>
      				<span className="dec">&lt;/body&gt;</span><br/>
      				<span className="dec">&lt;/html&gt;</span><br/>
      				<br/>
      				</p>
      				<img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/html5/5-1.png`}/>
      				<p className="line">いかがでしょうか？<br/>
      				非常に簡素なページではありますが、<span className="emphasis">たったの数十行でも、これぐらいのモノは作成できる</span>と言うことがお分かり頂けたと思います。</p>
      				<p className="line">もっと色々と勉強して、自分好みのデザインにして、たくさんの人に見てもらえたら・・・物凄く楽しいと思いませんか？<br/>
      				あなたは今、その<span className="emphasis">大きな一歩</span>を踏み出したのです。<br/>
      				このサイトに掲載した内容を大体覚えておけば、この先色々な「入門書」を読んでも、比較的スムーズに理解が進むと思います。<br/>
      				是非、これからも勉強を続けていただき、いつか<span className="emphasis">インターネットの世界にあなただけのWebサイトを公開</span>してみてくださいね。</p>
            </section>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="ad">
            広告エリア2
          </div>
        </Grid>
      </Grid>
    </div>
    </Zoom>
  );
}
