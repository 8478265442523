import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import ScrollToTopOnMount from '../Common/ScrollToTopOnMount';
import Zoom from '@material-ui/core/Zoom';
import Grid from '@material-ui/core/Grid';
import LinkBtn from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      minWidth: 240,
      width: '100%',
      overflow: 'hidden',
    },
    image: {
        position: 'relative',
        maxWidth: '100%',
        height: 'auto',
        cursor: 'pointer',
        transitionDuration: '0.3s',
        '&:hover': {
          zIndex: 1,
          transitionDuration: '0.3s',
          opacity: 0.6,
        },
      },
}));

export default function Home() {
    const classes = useStyles();
    return (
        <Zoom in={true}>
          <div>
          <ScrollToTopOnMount />
          <div className={classes.root}>
          <br/>
          <Grid container alignItems="center" justify="center" spacing="2">
            <Grid item xs={12} sm={6} >
              <LinkBtn component={Link} to="/moeropro"><img alt="" className={classes.image} src={`${process.env.PUBLIC_URL}/static/images/banner_moero.png`} /></LinkBtn>
            </Grid>
            <Grid item xs={12} sm={6} >
              <a href="https://conga.x-born.net/" target="_brank">
              <img alt="" className={classes.image} src={`${process.env.PUBLIC_URL}/static/images/banner_conga.png`} />
              </a>
            </Grid>
            </Grid>
          </div>
          </div>
        </Zoom>
      );
}
