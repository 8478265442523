import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';

import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { withStyles } from '@material-ui/core/styles';

import ResponsiveDrawerListItem from './ResponsiveDrawerListItem';
import '../Common/Common.css';
import Fab from '@material-ui/core/Fab';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const drawerWidth = 220;
const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    background: 'linear-gradient(to right, rgb(0, 50, 128), rgba(0, 128, 255,0.2))',

    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#e9e9fa',
    margin: '0 0 100vh 0',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#ffffff',
  },
  image:{
    maxHeight: '40px',
    width: 'auto',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 20,
    opacity: '0.8',
  },
});

class ResponsiveDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
    };
  }

  closeDrawerNav = () => {
    this.setState({ mobileOpen: false });
  }
  openDrawerNav = () => {
    this.setState({ mobileOpen: true });
  }

  render() {

    const drawer = (
      <div>
        <List>
          <ResponsiveDrawerListItem
            to="/"
            onClick={this.closeDrawerNav}
            icon={<HomeIcon />}
            text="ホーム"
          />
          <ResponsiveDrawerListItem
            to="/company"
            onClick={this.closeDrawerNav}
            icon={<BusinessIcon />}
            text="会社概要"
          />
          <ResponsiveDrawerListItem
            to="/contents"
            onClick={this.closeDrawerNav}
            icon={<AssignmentIcon />}
            text="コンテンツ"
          />
        </List>
      </div>
    );

    return (
      <div className={this.props.classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={this.props.classes.appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => this.openDrawerNav()}
                  className={this.props.classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <img alt="" className={this.props.classes.image} src={`${process.env.PUBLIC_URL}/static/images/logo.png`} />
              </Toolbar>
            </AppBar>
            <nav className={this.props.classes.drawer}>
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden smUp implementation="css">
                <Drawer
                  variant="temporary"
                  anchor={this.props.theme.direction === 'rtl' ? 'right' : 'left'}
                  open={this.state.mobileOpen}
                  onClose={this.closeDrawerNav}
                  classes={{
                    paper: this.props.classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden xsDown implementation="css">
                <Drawer
                  classes={{
                    paper: this.props.classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
            <section id="top"></section>
            <main className={this.props.classes.content}>
              <div className={this.props.classes.toolbar} />
              {this.props.children}
              <ul className="circles">
              <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
              </ul>
            </main>
            <AnchorLink href='#top'>
              <Fab color="primary" aria-label="top" className={this.props.classes.fab}>
                <VerticalAlignTopIcon />
              </Fab>
            </AnchorLink>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ResponsiveDrawer);
