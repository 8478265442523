import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ScrollToTopOnMount from '../Common/ScrollToTopOnMount';
import Zoom from '@material-ui/core/Zoom';
import Grid from '@material-ui/core/Grid';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import '../Common/Common.css';
import '../Common/Moeropro.css';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function Js() {

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);

  const handleClick1 = () => {
    setOpen1(!open1);
  };
  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const handleClick3 = () => {
    setOpen3(!open3);
  };

  return (
    <Zoom in={true}>
    <div>
      <ScrollToTopOnMount />
      <Typography variant='h6' className='Sub-title' gutterBottom>
        <Box fontWeight='fontWeightBold'>Javascript</Box>
      </Typography>
      <Grid container>
        <Grid item xs={12} md={12}>
          <List component="nav" aria-labelledby="nested-list-subheader" className="root">
            <ListItem button onClick={handleClick1}>
              <ListItemText primary="１．JavaScript基礎講座" />
              {open1 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <AnchorLink href='#1-1' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="１－１．JavaScriptって何だろう？" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#1-2' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="１－２．何ができるんだろう？" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#1-3' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="１－３．何を準備すればいいんだろう？" />
                  </ListItem>
                </AnchorLink>
                <AnchorLink href='#1-4' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="１－４．どうやって動かすんだろう？" />
                  </ListItem>
                </AnchorLink>
              </List>
            </Collapse>
            <ListItem button onClick={handleClick2}>
              <ListItemText primary="２．JavaScript実践講座" />
              {open2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <AnchorLink href='#2-1' offset='80'>
                  <ListItem button className="nested">
                    <ListItemText primary="２－１．変数を理解しよう" />
                  </ListItem>
                </AnchorLink>
                <ListItem button className="nested">
                  <ListItemText primary="２－２．データ型を理解しよう" />
                </ListItem>
                <ListItem button className="nested">
                  <ListItemText primary="２－３．配列を理解しよう" />
                </ListItem>
                <ListItem button className="nested">
                  <ListItemText primary="２－４．コメントを理解しよう" />
                </ListItem>
                <ListItem button className="nested">
                  <ListItemText primary="２－５．メッセージダイアログを表示してみよう" />
                </ListItem>
                <ListItem button className="nested">
                  <ListItemText primary="２－６．処理を分岐させてみよう" />
                </ListItem>
                <ListItem button className="nested">
                  <ListItemText primary="２－７．処理を繰り返してみよう" />
                </ListItem>
                <ListItem button className="nested">
                  <ListItemText primary="２－８．関数を理解しよう" />
                </ListItem>
                <ListItem button className="nested">
                  <ListItemText primary="２－９．文字や背景の色を変えてみよう" />
                </ListItem>
                <ListItem button className="nested">
                  <ListItemText primary="２－１０．タイマーを使ってみよう" />
                </ListItem>
                <ListItem button className="nested">
                  <ListItemText primary="２－１１．乱数を使ってみよう" />
                </ListItem>
              </List>
            </Collapse>
            <ListItem button onClick={handleClick3}>
              <ListItemText primary="３．JavaScript応用講座" />
              {open3 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open3} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className="nested">
                  <ListItemText primary="３－１．ワリカンプログラムを作ってみよう" />
                </ListItem>
                <ListItem button className="nested">
                  <ListItemText primary="３－２．おみくじプログラムを作ってみよう" />
                </ListItem>
                <ListItem button className="nested">
                  <ListItemText primary="３－３．じゃんけんプログラムを作ってみよう" />
                </ListItem>
                <ListItem button className="nested">
                  <ListItemText primary="３－４．デジタル時計プログラムを作ってみよう" />
                </ListItem>
                <ListItem button className="nested">
                  <ListItemText primary="３－５．お散歩プログラムを作ってみよう" />
                </ListItem>
                <ListItem button className="nested">
                  <ListItemText primary="３－６．神経衰弱プログラムを作ってみよう" />
                </ListItem>
              </List>
            </Collapse>
            <div>
    					<p className="nested">↓続きはこちらで</p>
              <table>
                <tr>
                  <td><a href="https://px.a8.net/svt/ejp?a8mat=2BNL8L+56CRFU+249K+BWGDT&a8ejpredirect=https%3A%2F%2Fwww.amazon.co.jp%2Fdp%2FB01LYUB8EP%2F%3Ftag%3Da8-affi-278416-22" target="_blank" rel="nofollow noreferrer noopener"><img border="0" alt="" src="https://images-fe.ssl-images-amazon.com/images/I/417W48rfgPL._SS80_.jpg" /></a></td>
                  <td><p><a href="https://px.a8.net/svt/ejp?a8mat=2BNL8L+56CRFU+249K+BWGDT&a8ejpredirect=https%3A%2F%2Fwww.amazon.co.jp%2Fdp%2FB01LYUB8EP%2F%3Ftag%3Da8-affi-278416-22" target="_blank" rel="nofollow noreferrer noopener">はじめてのJavaScriptプログラミング</a></p></td>
                </tr>
              </table>
    				</div>
          </List>
        </Grid>
        <Grid item xs={12} md={12}>
          <div className="ad">
            広告エリア１
          </div>
        </Grid>
        <Grid item xs={12} md={8}>
          <div className="main_content">
            <h2>１．JavaScript基礎講座</h2>
            <section id='1-1'>
              <h3>１－１．JavaScriptって何だろう？</h3>
              <p className="line">JavaScriptは、ズバリ「プログラミング言語」です。<br/>
              そう、プログラムを作るための言語です。<br/>
              世の中には様々なプログラミング言語が存在しますが、それらの中でもかなり人気があるのがJavaScriptです。<br/>
              ちなみに「ジャバスクリプト」と読みます。<br/>
              なお、世の中には「Java」と言う名前が良く似た言語も存在するのですが、JavaScriptとJavaは全くの別物なので混同しないよう注意してください。</p>
              <p className="line">JavaScriptは、手軽に始められ、比較的覚えやすいことから年々その人気が高まっています。<br/>
              しかし、JavaScriptは手軽さに反して非常にパワフルで奥深い言語なため、基礎を疎かにして小手先で覚えてしまうと後々にとても痛い目に合ってしまいます。<br/>
              ですので、あせらず一歩一歩確実に覚えるようにして行きましょう。<br/>
              とは言うものの、あまり過度な心配はいりません。<br/>
              冒頭でも述べた通り、プログラミング上達のコツは楽しむことです。<br/>
              まずは、JavaScriptの手軽さを最大限に活かして、存分に楽しむところから初めてみましょう。</p>            </section>
            <section id='1-2'>
              <h3>１－２．何ができるんだろう？</h3>
              <p className="line">JavaScriptは、主に「Webブラウザ上で動くプログラムを作るための言語」です。<br/>
              Webブラウザとは、Internet ExplorerやGoogle Chromeなどの「Webサイト（ホームページ）を見るため」のアプリケーションなので、「Webサイト上で動くプログラムを作るための言語」とも言えます。</p>
              <p className="line">Webサイト上で動くプログラムと言っても多種多様ですが、例えば有名なところだとGoogleMapやGmailがあります。<br/>
              それらの有名で高機能なWebサイトでも、JavaScriptは活躍しています。<br/>
              とは言え、これらは極端な例です。<br/>
              まずは、JavaScriptを使うと「HTML文書に様々な動きを加えられる」と理解してもらえればと思います。<br/>
              ここで言う「様々な動き」とは、例えばボタンをクリックしたらメッセージが表示されるとか、一定の時間が経過したら背景の色が変わるとか、そう言ったものを想像してください。<br/>
              HTML文書はその名の通り「文章を表すための物」なのですが、JavaScriptを使うことによって様々な動きを加えることが出来るのです。</p>
              <img className="sample" alt="" src={`${process.env.PUBLIC_URL}/static/images/js/1-2.png`}/>
            </section>
            <section id='1-3'>
              <h3>１－３．何を準備すればいいんだろう？</h3>
              <p className="line">「プログラムを作る」となると、準備が大変なイメージがあると思います。<br/>
              一般的なイメージとしては、「専用のソフトウェアを買って、プログラムを動かすための準備をして・・・」のような感じでしょうか。<br/>
              しかし、心配ご無用です。<br/>
              JavaScriptでプログラムを作る場合、極端な表現をすれば「パソコン一台あればOK」です。<br/>
              特別なソフトウェアは必要ありませんし、何か準備を行う必要もありません。<br/>
              もっと正確な表現をするならば、「何らかのテキストエディタ」があれば、JavaScriptのプログラムは作れます。<br/>
              例えばWindowsであれば、最初からインストールされている「メモ帳」でも問題ありませんし、Macでも最初からインストールされている「テキストエディット」で作ることができます。<br/>
              要するに、あなたが普段使っているパソコンで、今すぐにでもJavaScriptのプログラムが作れると言うことです。<br/>
              ですが、WindowsにしろMacにしろ、標準でインストールされているテキストエディタは本格的に使い出すと何かと不便な点があります。<br/>
              なので、オススメのテキストエディタをご紹介します。</p>
              <p className="line2">■Windowsの場合<br/>
              <a href="http://sakura-editor.sourceforge.net/" target="_blank" rel="noreferrer noopener">サクラエディタ</a>（http://sakura-editor.sourceforge.net/）<br/>
              このエディタは開発現場のプロ達でも好んで使う人が多いぐらい、非常に高性能なエディタです。<br/>
              しかも無料で使えるので、気軽に試してみてもらえればと思います。</p>
              <p className="line2">■Macの場合<br/>
              <a href="https://coteditor.com/" target="_blank" rel="noreferrer noopener">CotEditor</a>（https://coteditor.com/）<br/>
              日本製のエディタで、シンプルで非常に使いやすいエディタです。<br/>
              こちらも無料なので、Macユーザーの人は是非試してみてください。</p>
            </section>
            <section id='1-4'>
              <h3>１－４．どうやって動かすんだろう？</h3>
              <p className="line">ここまでの説明で、JavaScriptのプログラムは何を使って作ればいいのかは理解してもらえたと思います。<br/>
              では次に、いよいよ作ったプログラムの動かし方を説明します。</p>
              <p className="line">１－２で説明した通り、JavaScriptでは基本的に「Webブラウザ上で動くプログラム」を作ります。<br/>
              ですので、何らかのWebブラウザがあれば、自分で作ったプログラムも動かせると言うことです。<br/>
              また、WebブラウザはWindowsの場合はInternet Explorerが、Macの場合はSafariが最初からインストールされているため、何も特別な準備は必要ないと言うことになります。<br/>
              しかし、JavaScriptのプログラムは、それ単体では動きません。<br/>
              これも１－２で説明している通り、JavaScriptは「Webサイト上で動くプログラム」なので、基本的にはHTMLファイルとセットで動作させる必要があります。<br/>
              とは言え、HTMLも何か特別な環境を用意しなくても作成し、動作させることが可能なので、何も特別な準備が必要ないことには変わりません。</p>
              <p className="line">なお、Windowsの場合、標準でインストールされているInternet Explorerよりも、Google Chromeを利用することを強くオススメします。<br/>
              Google Chromeには非常に強力な「開発者ツール」が搭載されており、いずれプログラムの腕が上達した際にはとても便利な存在となります。<br/>
              ですので、今のうちからGoogle Chromeを使うことに慣れておくことが望ましいでしょう。</p>
              <p className="line">さて、これで「JavaScript基礎講座」は終わりです。<br/>
              次からはいよいよ実践的な内容になりますが、あまり難しく考えなくて大丈夫ですので、是非楽しみながら読み進めてください。<br/>
              また、可能であれば、<span className="emphasis2">読み進めるのと同時に内容を自分でも実践してみてください</span>。</p>
            </section>
            <h2>２．JavaScript実践講座</h2>
            <section id='2-1'>
              <h3 id="sigil_toc_id_10">２－１．変数を理解しよう</h3>
              <p className="line">変数とは、何らかの「値」を入れておくための「名前付きの箱」のような物です。<br/>
              例えば、コンビニにあるカゴを思い浮かべてください。<br/>
              買い物へいくと、カゴ（変数）へ商品（値）を入れていきますよね？<br/>
              そのカゴ（変数）には、商品（値）を自由に出したり入れたりすることができます。<br/>
              そして、支払いをする時に、カゴ（変数）から商品（値）を取り出して会計をする。<br/>
              このように、買い物をする時のカゴのような働きをする物が、「変数」となります。<br/>
              最初は何のために使うのかいまいちピンと来ないと思いますが、変数はプログラミングを行う上で非常に重要な要素となるため、しっかり理解しておきましょう。</p>
              <p className="line">JavaScriptでは、次のような書き方で変数を用意します。<br/>
              ちなみに、このように「変数を用意する」行為を、プログラミングの世界では「変数を宣言する」と言うので、覚えておいてください。</p>
              <p style={{marginBottom:'0px'}}><span className="samplenum">例：変数の宣言</span></p>
              <p className="sample">
              <br/>
              <span className="dec">let</span>&#160;hensu1;<br/>
              <br/>
              </p>
              <p className="line">この例では、hensu1と言う名前の変数を用意しました。<br/>
              最初に「let」と書いて、その後ろに半角のスペースを一つ書いて、さらにその後ろに変数の名前を書くルールになっています。<br/>
              変数の名前は半角のアルファベット、半角の数値、半角のアンダースコア（半角のアンダーバー）、半角のドル記号で構成されていて、かつ数字から始まってはいけないと言うルールがあります。（一部、ルールを満たしていても使用できない名前があります）<br/>
              また、変数宣言に限った話ではないのですが、JavaScriptでプログラムを書く場合、末尾に必ずセミコロン（;）を付けると言うことも合わせて覚えておいてください。<br/>
              なお、次のような書き方をすると、複数の変数を一気に宣言することが出来ます。</p>
              <p style={{marginBottom:'0px'}}><span className="samplenum">例：複数の変数宣言</span></p>
              <p className="sample">
              <br/>
              <span className="dec">let</span>&#160;hensu1,hensu2,hensu3;<br/>
              <br/>
              </p>
              <p className="line">この例では、hensu1、hensu2、hensu3と言う名前の変数を用意しました。<br/>
              変数名を半角のカンマで区切って、必要な分だけ変数名を書けばOKです。</p>
              <p className="line">では次に、用意した変数に何か値を入れてみたいと思います。<br/>
              この「変数に値を入れる」と言う行為は、プログラミングの世界では「代入」と言います。</p>
              <p style={{marginBottom:'0px'}}><span className="samplenum">例：値の代入</span></p>
              <p className="sample">
              <br/>
              <span className="dec">let</span>&#160;hensu1,hensu2;<br/>
              <br/>
              hensu1&#160;=&#160;100;<br/>
              hensu2&#160;=&#160;'あいうえお';<br/>
              <br/>
              </p>
              <p className="line">値の代入は半角のイコール（=）で行います。<br/>
              変数名、半角のスペース、半角のイコール、半角のスペース、変数に代入する値の順番で書きます。<br/>
              この例では、hensu1には「100」を、hensu2には、「あいうえお」をそれぞれ代入しています。（「あいうえお」の前後に付いている「&#39;」はこの先で説明するので、今はあまり気にしないでください）<br/>
              なお、JavaScriptでは、変数の宣言と同時に値を代入することも可能です。</p>
              <p style={{marginBottom:'0px'}}><span className="samplenum">例：変数宣言と値の代入を同時に行う</span></p>
              <p className="sample">
              <br/>
              <span className="dec">let</span>&#160;hensu1&#160;=&#160;100,hensu2&#160;=&#160;'あいうえお';<br/>
              <span className="dec">let</span>&#160;hensu3&#160;=&#160;200;<br/>
              <span className="dec">let</span>&#160;hensu4&#160;=&#160;'かきくけこ';<br/>
              <br/>
              </p>
              <p className="line">さて、変数についての説明はひとまずここまでになります。<br/>
              定義した変数は一体どのような時に使うのか、どのように使うのかはこの先々で多々出て来ますので、今のところは「宣言」と「代入」についてしっかり覚えておきましょう。</p>
              <p className="line">これ以降、興味のある方や続きの見たい方は以下の本をお買いいただけると幸いです。</p>
              <table>
                <tr>
                  <td><a href="https://px.a8.net/svt/ejp?a8mat=2BNL8L+56CRFU+249K+BWGDT&a8ejpredirect=https%3A%2F%2Fwww.amazon.co.jp%2Fdp%2FB01LYUB8EP%2F%3Ftag%3Da8-affi-278416-22" target="_blank" rel="nofollow noreferrer noopener"><img border="0" alt="" src="https://images-fe.ssl-images-amazon.com/images/I/417W48rfgPL._SS80_.jpg" /></a></td>
                  <td><p><a href="https://px.a8.net/svt/ejp?a8mat=2BNL8L+56CRFU+249K+BWGDT&a8ejpredirect=https%3A%2F%2Fwww.amazon.co.jp%2Fdp%2FB01LYUB8EP%2F%3Ftag%3Da8-affi-278416-22" target="_blank" rel="nofollow noreferrer noopener">はじめてのJavaScriptプログラミング</a></p></td>
                </tr>
              </table>
            </section>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className="ad">
            広告エリア2
          </div>
        </Grid>
      </Grid>
    </div>
    </Zoom>
  );
}
