import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import ScrollToTopOnMount from '../Common/ScrollToTopOnMount';
import Zoom from '@material-ui/core/Zoom';
import Grid from '@material-ui/core/Grid';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import '../Common/Common.css';
import '../Common/Moeropro.css';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export default function Css3() {

const [open1, setOpen1] = React.useState(false);
const [open2, setOpen2] = React.useState(false);
const [open3, setOpen3] = React.useState(false);

const handleClick1 = () => {
  setOpen1(!open1);
};
const handleClick2 = () => {
  setOpen2(!open2);
};
const handleClick3 = () => {
  setOpen3(!open3);
};

const useStyles = makeStyles(theme => ({
    mb0:{
        marginBottom:0,
    },
    traqua:{
        backgroundColor:'aqua'
    },
    td20:{
        width:'20%',
        textAlign:'center',
    },
    td25:{
        width:'25%',
        textAlign:'center',
    },
    td35:{
        width:'35%',
        textAlign:'center',
    },
    td40:{
        width:'40%',
        textAlign:'center',
    },
    td45:{
        width:'45%',
        textAlign:'center',
    },
    td50:{
        width:'50%',
        textAlign:'center',
    },
    td60:{
        width:'60%',
        textAlign:'center',
    },
    td75:{
        width:'75%',
        textAlign:'center',
    },

}));

const styles = useStyles();

return (

    <Zoom in={true}>
    <div>

    <ScrollToTopOnMount />
    <Typography variant='h6' className='Sub-title' gutterBottom>
      <Box fontWeight='fontWeightBold'>CSS3</Box>
    </Typography>
    <Grid container>
      <Grid item xs={12} md={12}>
        <List component="nav" aria-labelledby="nested-list-subheader" className="root">
          <ListItem button onClick={handleClick1}>
            <ListItemText primary="１．CSS基礎講座" />
            {open1 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open1} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <AnchorLink href='#1-1' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="１−１．CSSとは" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#1-2' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="１−２．CSSの書き方" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#1-3' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="１−３．セレクタ" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#1-4' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="１−４．CSSで扱う単位" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#1-5' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="１−５．色の指定" />
                </ListItem>
              </AnchorLink>
            </List>
          </Collapse>
          <ListItem button onClick={handleClick2}>
            <ListItemText primary="２．基本的な使い方" />
            {open2 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open2} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <AnchorLink href='#2-1' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="２−１．背景の色を変える" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#2-2' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="２−２．文字の色を変える" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#2-3' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="２−３．文字の大きさを変える" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#2-4' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="２−４．文字のスタイルを変える" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#2-5' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="２−５．枠を付ける" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#2-6' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="２−６．複数の要素に同じスタイルを適用する" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#2-7' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="２−７．子孫要素に同じスタイルを適用する" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#2-8' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="２−８．子要素に同じスタイルを適用する" />
                </ListItem>
              </AnchorLink>
            </List>
          </Collapse>
          <ListItem button onClick={handleClick3}>
            <ListItemText primary="３．ちょっとだけ高度な使い方" />
            {open3 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open3} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <AnchorLink href='#3-1' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="３−１．ボックスモデルについて" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#3-2' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="３−２．マージン、ボーダー、パディング" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#3-3' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="３−３．リンクをカスタマイズする" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#3-4' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="３−４．背景に画像を表示させる" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#3-5' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="３−５．要素の直前、直後をカスタマイズする" />
                </ListItem>
              </AnchorLink>
              <AnchorLink href='#3-6' offset='80'>
                <ListItem button className="nested">
                  <ListItemText primary="３−６．条件に該当する要素だけを対象とする" />
                </ListItem>
              </AnchorLink>
            </List>
          </Collapse>
        </List>
      </Grid>
      <Grid item xs={12} md={12}>
        <div className="ad">
          広告エリア１
        </div>
      </Grid>
      <Grid item xs={12} md={8}>
          <div className="main_content">
            <h2>１．CSS基礎講座</h2>
            <section id='1-1'>
              <h3>１−１．CSSとは</h3>
              <p class="line">CSSは、Cascading Style Sheet （カスケーディング・スタイルシート）の略称です。<br/>
				これを使うことにより、HTML文書に様々なデザインを加えることが可能になります。<br/>
				また、ここでは触れませんが、高度な使い方をするとアニメーションを加えることも可能になります。<br/>
				現在では、Webサイトの<span class="emphasis">デザインは全てCSSで行う</span>ルールとなっているため、Web開発に携わる人にとっては必須の知識です。</p>
				<p class="line">なお、現在のCSSは「CSS3」と呼ばれるバージョンの物が主流となっています。<br/>
				ここでは、特に説明が無い限りはこのバージョンを前提としています。</p>
            </section>
            <section id='1-2'>
            <h3>１－２．CSSの書き方</h3>
				<p class="line">CSSは、基本的に「<span class="emphasis">どれの</span>」「<span class="emphasis">何を</span>」「<span class="emphasis">どうするか</span>」と言う書き方をします。<br/>
				例えば「<span class="emphasis">Pタグの</span>」「<span class="emphasis">文字色を</span>」「<span class="emphasis">赤くする</span>」みたいな感じです。<br/>
				実際にはこんな感じで書きます。</p>
				<p class="sample">
				<br/>
				p&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;color:&#160;red;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<p class="line">これを先ほどの表現に当てはめるとこんな感じになります。</p>
				<p class="sample">
				<br/>
				<span class="emphasis">どれの</span>&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;<span class="emphasis">何を</span>:&#160;<span class="emphasis">どうするか</span>;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<p class="line">ポイントは以下の通りです。<br/>
				<span class="indent2em">・「<span class="emphasis">どれの</span>」の単位で波括弧（<span class="emphasis">{ }</span>）で括る</span><br/>
				<span class="indent2em">・「<span class="emphasis">何を</span>」と「<span class="emphasis">どうするか</span>」の間にはコロン（<span class="emphasis">:</span>）を入れる</span><br/>
				<span class="indent2em">・「<span class="emphasis">どうするか</span>」の最後はセミコロン（<span class="emphasis">;</span>）を付ける</span><br/>
				これらは基本中の基本なので、必ず覚えるようにしてください。<br/>
				なお、各キーワードにはそれぞれ呼び方があり、<br/>
				「どれの」　→　<span class="emphasis">セレクタ</span><br/>
				「何を」　→　<span class="emphasis">プロパティ</span><br/>
				「どうするか」　→　<span class="emphasis">値</span><br/>
				このようになっています。<br/>
				合わせて覚えておいてください。</p>
				<p class="line">ちなみに、複数の指定をする場合は、次のように書きます。</p>
				<p class="sample">
				<br/>
				p&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;color:&#160;red;<br/>
				&#160;&#160;&#160;&#160;font-size:&#160;14pt;<br/>
				&#x7D;<br/>
				<br/>
				</p>
            </section>
            <section id='1-3'>
            <h3>１－３．セレクタ</h3>
				<p class="line">セレクタは、CSSにおいて<span class="emphasis">最も重要な概念</span>と言っても過言ではありません。<br/>
				セレクタはスタイルを適用する要素を指定するための物ですが、いきなりその全てを知ろうとするのは<span class="emphasis">無謀</span>です。<br/>
				なので、まずは次の三種類を「完璧に」覚えておきましょう。</p>
				<b>■IDセレクタ</b>
				<p class="line">IDセレクタは、HTML要素のID属性を指定して、スタイルを適用する要素を特定します。<br/>
				このセレクタは、シャープ記号（#)の後にID名を指定するルールになっています。</p>
				<p className={styles.mb0}><span class="samplenum">例</span></p>
				<p class="sample">
				<br/>
				<span class="dec">&lt;!DOCTYPE&#160;html&gt;</span><br/>
				<span class="dec">&lt;html</span>&#160;<span class="attr">lang</span>="ja"<span class="dec">&gt;</span><br/>
				<span class="dec">&lt;head&gt;</span><br/>
				<span class="dec">&lt;meta</span>&#160;<span class="attr">charset</span>="utf-8"<span class="dec">&gt;</span><br/>
				<span class="dec">&lt;style&gt;</span><br/>
				&#160;&#160;&#160;&#160;#sample&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;color:&#160;red;<br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;font-size:&#160;20pt;<br/>
				&#160;&#160;&#160;&#160;&#x7D;<br/>
				<span class="dec">&lt;/style&gt;</span><br/>
				<span class="dec">&lt;/head&gt;</span><br/>
				<span class="dec">&lt;body&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;p</span>&#160;<span class="attr">id</span>="sample"<span class="dec">&gt;</span>さんぷる１<span class="dec">&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;p</span>&#160;<span class="attr">id</span>="sample2"<span class="dec">&gt;</span>さんぷる２<span class="dec">&lt;/p&gt;</span><br/>
				<span class="dec">&lt;/body&gt;</span><br/>
				<span class="dec">&lt;/html&gt;</span><br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/1-3-1.png" alt='selector'/>
				<p class="line">この例では、「#sample」と言うセレクタを用意しています。<br/>
				シャープ記号から始まるセレクタなので、指定したスタイルは「sampleと言うIDを持つ要素」に対して適用されます。</p>
				<b>■クラスセレクタ</b>
				<p class="line">クラスセレクタは、HTML要素のclass属性を指定して、スタイルを適用する要素を特定します。<br/>
				このセレクタは、ドット（.)の後にクラス名を指定するルールになっています。</p>
				<p className={styles.mb0}>><span class="samplenum">例</span></p>
				<p class="sample">
				<br/>
				<span class="dec">&lt;!DOCTYPE&#160;html&gt;</span><br/>
				<span class="dec">&lt;html</span>&#160;<span class="attr">lang</span>="ja"<span class="dec">&gt;</span><br/>
				<span class="dec">&lt;head&gt;</span><br/>
				<span class="dec">&lt;meta</span>&#160;<span class="attr">charset</span>="utf-8"<span class="dec">&gt;</span><br/>
				<span class="dec">&lt;style&gt;</span><br/>
				&#160;&#160;&#160;&#160;.sample&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;color:&#160;red;<br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;font-size:&#160;20pt;<br/>
				&#160;&#160;&#160;&#160;&#x7D;<br/>
				<span class="dec">&lt;/style&gt;</span><br/>
				<span class="dec">&lt;/head&gt;</span><br/>
				<span class="dec">&lt;body&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;p</span>&#160;<span class="attr">class</span>="sample"&gt;さんぷる１<span class="dec">&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;p</span>&#160;<span class="attr">class</span>="sample2"&gt;さんぷる２<span class="dec">&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;p</span>&#160;<span class="attr">class</span>="sample"&gt;さんぷる３<span class="dec">&lt;/p&gt;</span><br/>
				<span class="dec">&lt;/body&gt;</span><br/>
				<span class="dec">&lt;/html&gt;</span><br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/1-3-2.png" alt=''/>
				<p class="line">この例では、「.sample」と言うセレクタを用意しています。<br/>
				ドットから始まるセレクタなので、指定したスタイルは「sampleと言うclassを持つ要素」に対して適用されます。</p>
				<b>■タイプセレクタ</b>
				<p class="line">クラスセレクタは、HTML要素の要素（タグ）名を指定して、指定した全ての要素にスタイルを適用します。</p>
				<p style={{marginBottom:0}}>><span class="samplenum">例</span></p>
				<p class="sample">
				<br/>
				<span class="dec">&lt;!DOCTYPE&#160;html&gt;</span><br/>
				<span class="dec">&lt;html</span>&#160;<span class="attr">lang</span>="ja"<span class="dec">&gt;</span><br/>
				<span class="dec">&lt;head&gt;</span><br/>
				<span class="dec">&lt;meta</span>&#160;<span class="attr">charset</span>="utf-8"<span class="dec">&gt;</span><br/>
				<span class="dec">&lt;style&gt;</span><br/>
				&#160;&#160;&#160;&#160;p&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;color:&#160;red;<br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;font-size:&#160;20pt;<br/>
				&#160;&#160;&#160;&#160;&#x7D;<br/>
				<span class="dec">&lt;/style&gt;</span><br/>
				<span class="dec">&lt;/head&gt;</span><br/>
				<span class="dec">&lt;body&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>さんぷる１<span class="dec">&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>さんぷる２<span class="dec">&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;div&gt;</span>さんぷる３<span class="dec">&lt;/div&gt;</span><br/>
				<span class="dec">&lt;/body&gt;</span><br/>
				<span class="dec">&lt;/html&gt;</span><br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/1-3-3.png" alt='ptag'/>
				<p class="line">この例では、Pタグをセレクタに指定しています。<br/>
				指定したスタイルは、全てのPタグに対して適用されます。</p>
            </section>
            <section id='1-4'>
            <h3>１－４．CSSで扱う単位</h3>
				<p class="line">CSSでは、大きさや長さなどを指定する際に、様々な単位を利用します。<br/>
				単位には<span class="emphasis">絶対単位</span>と<span class="emphasis">相対単位</span>の二種類があるので、それぞれの意味や種類をしっかりと理解しておきましょう。</p>
				<b>■絶対単位</b>
				<table border="1" class="tLine">
				    <tbody>
				        <tr className={styles.traqua}>
				            <td className={styles.td20}>単位</td>
				            <td className={styles.td35}>意味</td>
				            <td className={styles.td45}>備考</td>
				        </tr>
				        <tr>
				            <td>cm</td>
				            <td>センチメートル</td>
				            <td>&#160;</td>
				        </tr>
				        <tr>
				            <td>mm</td>
				            <td>ミリメートル</td>
				            <td>&#160;</td>
				        </tr>
				        <tr>
				            <td>in</td>
				            <td>インチ</td>
				            <td>１インチは2.54cm</td>
				        </tr>
				        <tr>
				            <td>pt</td>
				            <td>ポイント</td>
				            <td>１ポイントは1/72インチ</td>
				        </tr>
				        <tr>
				            <td>pc</td>
				            <td>パイカ</td>
				            <td>１パイカは12pt</td>
				        </tr>
				    </tbody>
				</table>
				<p class="line">絶対単位は、その名の通りブラウザ上に表示されるサイズを「絶対的なサイズ」として指定します。<br/>
				例えば、「10cm」と指定したのであれば、そのまま10cmの大きさになると言うことです。<br/>
				「どんなデバイスでも必ずこの大きさで表示して欲しい」みたいな場合にはこれらを利用します。</p>
				<b>■相対単位</b>
				<table border="1" class="tLine">
				    <tbody>
				        <tr className={styles.traqua}>
				            <td className={styles.td25}>単位</td>
				            <td className={styles.td75}>説明</td>
				        </tr>
				        <tr>
				            <td>em</td>
				            <td>適用要素のfont-sizeを「1」としたその倍率</td>
				        </tr>
				        <tr>
				            <td>rem</td>
				            <td>ルート要素のfont-sizeを「1」としたその倍率</td>
				        </tr>
				        <tr>
				            <td>ex</td>
				            <td>適用要素の英小文字「x」の高さを「1」としたその倍率	</td>
				        </tr>
				        <tr>
				            <td>px</td>
				            <td>１ピクセルを「１」とした単位	</td>
				        </tr>
				        <tr>
				            <td>%</td>
				            <td>あるものを基準とする単位</td>
				        </tr>
				    </tbody>
				</table>
				<p class="line">相対単位は、「何らか」のサイズと比較した「相対的なサイズ」として指定します。<br/>
				<span class="emphasis">em</span>、<span class="emphasis">px</span>辺りは比較的利用シーンの多い単位なので、覚えておきましょう。<br/>
				また、<span class="emphasis">rem</span>はCSS3から登場した新しい単位なのですが、<span class="emphasis">em</span>の使い辛さを改良したようなイメージで、非常に使い勝手のいい単位となっています。</p>

            </section>
            <section id='1-5'>
            <h3>１－５．色の指定</h3>
				  <p class="line">CSSで何らかの色（文字色や背景色など）を指定する場合、大きく分けると二種類のやり方が存在します。</p>
				<b>■カラーネームでの指定</b>
				  <p class="line">二種類ある方法のうち、こちらが簡単な方法になります。<br/>
				先の例でも使用した方法ですが、値に「<span class="emphasis">red</span>」とか「<span class="emphasis">blue</span>」などの色名を指定します。</p>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				p&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;color:&#160;red;<br/>
				&#160;&#160;&#160;&#160;background-color:&#160;black;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				  <p class="line">なお、指定可能なカラーネームはとても全てを覚えきれるような数ではないため、使用の都度「CSS　色見本」などでWebサイトを検索すると良いでしょう。</p>
				<b>■RGB値での指定</b>
				  <p class="line">もう一つの方法が、<span class="emphasis">RGB値</span>と呼ばれる値で指定する方法です。<br/>
				<span class="emphasis">RGB値</span>とは、Red、Green、Blueの頭文字から付けられた名前で、その名の通り赤、緑、青のそれぞれのカラーコードを指定します。<br/>
				また、RGB値は4種類の記述方法があり、用途に応じて使い分けることが可能です。</p>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				#sample1&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;color:&#160;#FFFFFF;<br/>
				&#x7D;<br/>
				<br/>
				#sample2&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;color:&#160;#A12;<br/>
				&#x7D;<br/>
				<br/>
				#sample3&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;color:&#160;rgb(100,110,120);<br/>
				&#x7D;<br/>
				<br/>
				#sample4&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;color:&#160;rgb(10%,20%,30%);<br/>
				&#x7D;<br/>
				<br/>
				</p>
				  <p class="line"><span class="emphasis">sample1</span>で指定した方法は、赤、緑、青のカラーコードをそれぞれ2桁の16進数の値で指定する方法です。<br/>
				それぞれ00が黒、FFが白を意味していています。<br/>
				実際にどんなコードを指定したらどんな色になるのかは、これもカラーネームと同じく色見本を検索するのが一番賢いやり方でしょう。</p>
				  <p class="line"><span class="emphasis">sample2</span>で指定した方法は、<span class="emphasis">sample1</span>で指定した方法の省略版になります。<br/>
				カラーコードを3桁で表記すると、「それぞれの1文字が二文字分指定されている」と言う解釈になります。<br/>
				例えば、ここで指定した「<span class="emphasis">A12</span>」は「<span class="emphasis">AA1122</span>」として扱われます。</p>
				  <p class="line"><span class="emphasis">sample3</span>で指定した方法は、赤、緑、青のカラーコードをそれぞれ10進数で指定する方法です。
				カラーコードは0～255の間で指定可能で、255が最も強い指定になります。</p>
				  <p class="line"><span class="emphasis">sample4</span>で指定した方法は、赤、緑、青のカラーコードをそれぞれパーセンテージで指定する方法です。
				値は100%が一番強い指定になります。</p>

            </section>
            <h2>２．基本的な使い方</h2>
            <section id='2-1'>
            <h3>２－１．背景の色を変える</h3>
				  <p class="line">背景色の指定には<span class="emphasis">background-color</span>プロパティを使用します。<br/>
				値には、１－５で説明した方法で色を指定します。</p>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				#sample1&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;background-color:&#160;dimgray;<br/>
				&#x7D;<br/>
				<br/>
				#sample2&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;background-color:&#160;#DCDCDC;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/2-1-1.png" alt='2-1'/>
            </section>
            <section id='2-2'>
            <h3>２－２．文字の色を変える</h3>
				  <p class="line">文字色の指定には<span class="emphasis">color</span>プロパティを使用します。<br/>
				値には、１－５で説明した方法で色を指定します。</p>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				#sample1&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;color:&#160;blue;<br/>
				&#x7D;<br/>
				<br/>
				#sample2&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;color:&#160;#FF0000;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/2-2-1.png" alt='2-2-1'/>
            </section>
            <section id='2-3'>
            <h3>２－３．文字の大きさを変える</h3>
				  <p class="line">文字の大きさは<span class="emphasis">font-size</span>プロパティで指定します。<br/>
				値には、「数値+単位」で指定する方法の他に、キーワードで指定する方法があります。<br/>
				指定可能なキーワードは以下の通りです。</p>
				<table border="1" class="tLine">
				    <tbody>
				        <tr className={styles.traqua}>
				            <td className={styles.tr40}>キーワード</td>
				            <td className={styles.tr60}>意味</td>
				        </tr>
				        <tr>
				            <td>xx-small</td>
				            <td>最も小さい</td>
				        </tr>
				        <tr>
				            <td>x-small</td>
				            <td>より小さい</td>
				        </tr>
				        <tr>
				            <td>small</td>
				            <td>小さい</td>
				        </tr>
				        <tr>
				            <td>medium</td>
				            <td>標準サイズ</td>
				        </tr>
				        <tr>
				            <td>large</td>
				            <td>大きい</td>
				        </tr>
				        <tr>
				            <td>x-large</td>
				            <td>より大きい</td>
				        </tr>
				        <tr>
				            <td>xx-large</td>
				            <td>最も大きい</td>
				        </tr>
				    </tbody>
				</table>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				#sample1&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-size:&#160;14pt;<br/>
				&#x7D;<br/>
				<br/>
				#sample2&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-size:&#160;xx-small;<br/>
				&#x7D;<br/>
				<br/>
				#sample3&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-size:&#160;x-small;<br/>
				&#x7D;<br/>
				<br/>
				#sample4&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-size:&#160;small;<br/>
				&#x7D;<br/>
				<br/>
				#sample5&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-size:&#160;medium;<br/>
				&#x7D;<br/>
				<br/>
				#sample6&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-size:&#160;large;<br/>
				&#x7D;<br/>
				<br/>
				#sample7&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-size:&#160;x-large;<br/>
				&#x7D;<br/>
				<br/>
				#sample8&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-size:&#160;xx-large;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/2-3-1.png" alt='2-3-1'/>
            </section>
            <section id='2-4'>
            <h3>２－４．文字のスタイルを変える</h3>
				  <p class="line"><span class="emphasis">font-style</span>プロパティを使用すると、文字を斜体にすることが出来ます。<br/>
				指定可能な値は以下の通りです。</p>
				<table border="1" class="tLine">
				    <tbody>
				        <tr className={styles.traqua}>
				            <td className={styles.tr40}>キーワード</td>
				            <td className={styles.tr60}>結果</td>
				        </tr>
				        <tr>
				            <td>normal</td>
				            <td>標準</td>
				        </tr>
				        <tr>
				            <td>italic</td>
				            <td>イタリック体</td>
				        </tr>
				        <tr>
				            <td>oblique</td>
				            <td>斜体	</td>
				        </tr>
				    </tbody>
				</table>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				#sample1&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-style:&#160;italic;<br/>
				&#x7D;<br/>
				<br/>
				#sample2&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-style:&#160;normal;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/2-4-1.png" alt='2-4-1'/>
				  <p class="line">次に、<span class="emphasis">text-decoration</span>プロパティを使用すると、文字を装飾出来ます。<br/>
				指定可能な値は以下の通りです。</p>
				<table border="1" class="tLine">
				    <tbody>
				        <tr className={styles.traqua}>
				            <td className={styles.td40}>キーワード</td>
				            <td className={styles.td60}>結果</td>
				        </tr>
				        <tr>
				            <td>none</td>
				            <td>装飾なし</td>
				        </tr>
				        <tr>
				            <td>underline</td>
				            <td>下線</td>
				        </tr>
				        <tr>
				            <td>overline</td>
				            <td>上線</td>
				        </tr>
				        <tr>
				            <td>line-through</td>
				            <td>取り消し線</td>
				        </tr>
				    </tbody>
				</table>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				#sample1&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;text-decoration:&#160;none;<br/>
				&#x7D;<br/>
				<br/>
				#sample2&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;text-decoration:&#160;underline;<br/>
				&#x7D;<br/>
				<br/>
				#sample3&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;text-decoration:&#160;overline;<br/>
				&#x7D;<br/>
				<br/>
				#sample4&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;text-decoration:&#160;line-through;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/2-4-2.png" alt='2-4-2'/>
				  <p class="line">次に、<span class="emphasis">font-weight</span>プロパティを使用すると、文字の太さを変更出来ます。<br/>
				指定可能な値は以下の通りです。</p>
				<table border="1" class="tLine">
				    <tbody>
				        <tr className={styles.traqua}>
				            <td className={styles.td40}>キーワード</td>
				            <td className={styles.td60}>結果</td>
				        </tr>
				        <tr>
				            <td>normal</td>
				            <td>標準</td>
				        </tr>
				        <tr>
				            <td>bold</td>
				            <td>一般的な太字</td>
				        </tr>
				        <tr>
				            <td>lighter</td>
				            <td>相対的に1段階細く</td>
				        </tr>
				        <tr>
				            <td>bolder</td>
				            <td>相対的に1段階太く</td>
				        </tr>
				    </tbody>
				</table>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				#sample1&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-weight:&#160;normal;<br/>
				&#x7D;<br/>
				<br/>
				#sample2&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-weight:&#160;bold;<br/>
				&#x7D;<br/>
				<br/>
				#sample3&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-weight:&#160;lighter;<br/>
				&#x7D;<br/>
				<br/>
				#sample4&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-weight:&#160;bolder;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/2-4-3.png" alt='2-4-3'/>
				  <p class="line">※<span class="emphasis">font-weight</span>プロパティには100から900の100刻みの数値を指定することも出来ますが、あまり利用されないためこのサイトでは割愛します</p>
				  <p class="line">続いて、<span class="emphasis">font-family</span>プロパティを使用すると、文字のフォントを指定することが出来ます。<br/>
				複数の候補を記述しておくことで、記述した順番にユーザ環境で利用可能なものが選択され、 より多くのユーザに対して使用するフォントを指定することが出来ます。<br/>
				なお、指定したフォントがユーザの環境で利用出来ない場合は、ブラウザのデフォルトのフォントで表示されます。<br/>
				また、フォント名にスペースが含まれている場合には、ダブルクォーテーション（"）またはシングルクォーテーション（'）で括る必要があります。</p>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				#sample1&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-family:&#160;'ＭＳ&#160;Ｐ明朝';<br/>
				&#x7D;<br/>
				<br/>
				#sample2&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-family:&#160;'ＭＳ&#160;ゴシック';<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/2-4-4.png" alt='2-4-4'/>
				  <p class="line">最後に、ここまで説明してきた指定をまとめて書く方法を紹介します。<br/>
				fontプロパティを使用すると、斜体、装飾、太字、フォントをまとめて指定出来ます。</p>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				#sample1&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font:&#160;italic&#160;bold&#160;14pt&#160;'ＭＳ&#160;Ｐ明朝';<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/2-4-5.png" alt='2-4-5'/>
            </section>
            <section id='2-5'>
            <h3>２－５．枠を付ける</h3>
				  <p class="line">要素に枠を付けるには、<span class="emphasis">border</span>プロパティを使用します。<br/>
				値には枠のスタイル、太さ、色のそれぞれを半角スペースで区切って指定します。<br/>
				スタイルに指定可能な値は以下の通りです。</p>
				<table border="1" class="tLine">
				    <tbody>
				        <tr className={styles.traqua}>
				            <td className={styles.td25}>キーワード</td>
				            <td className={styles.td75}>説明</td>
				        </tr>
				        <tr>
				            <td>none</td>
				            <td>初期値、枠が表示されない</td>
				        </tr>
				        <tr>
				            <td>hidden</td>
				            <td>枠が表示されない</td>
				        </tr>
				        <tr>
				            <td>solid</td>
				            <td>1本線の枠</td>
				        </tr>
				        <tr>
				            <td>double</td>
				            <td>2本線の枠</td>
				        </tr>
				        <tr>
				            <td>groove</td>
				            <td>立体的に窪んだ枠</td>
				        </tr>
				        <tr>
				            <td>ridge</td>
				            <td>立体的に隆起した枠</td>
				        </tr>
				        <tr>
				            <td>inset</td>
				            <td>上と左の線が暗く、下と右の線が明るい、領域全体が立体的に窪んだような枠</td>
				        </tr>
				        <tr>
				            <td>outset</td>
				            <td>上と左の線が明るく、下と右の線が暗くい、領域全体が立体的に隆起したような枠枠</td>
				        </tr>
				        <tr>
				            <td>dashed</td>
				            <td>破線の枠</td>
				        </tr>
				        <tr>
				            <td>dotted</td>
				            <td>点線の枠</td>
				        </tr>
				    </tbody>
				</table>
				  <p class="line">なお、太さは<span class="emphasis">「数値＋単位」</span>の指定が可能で、色は<span class="emphasis">カラーコード</span>か<span class="emphasis">カラーネーム</span>での指定が可能です。<br/>
				また、色を透明にしたい場合は<span class="emphasis">transparent</span>と指定します。</p>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				#sample1&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;border:&#160;solid&#160;2px&#160;black;<br/>
				&#x7D;<br/>
				<br/>
				#sample2&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;border:&#160;dotted&#160;5px&#160;red;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/2-5-1.png" alt='2-5-1'/>
				  <p class="line">次に、上下左右の線のそれぞれを個別に指定する方法を説明します。<br/>
				<span class="emphasis">border</span>プロパティでは、上下左右の線の全てを指定しますが、それぞれの線を個別に指定したい場合は、以下のプロパティを使用します。<br/>
				指定する値は、それぞれ<span class="emphasis">border</span>プロパティと同様です。</p>
				<table border="1" class="tLine">
				    <tbody>
				        <tr className={styles.traqua}>
				            <td className={styles.td50}>プロパティ</td>
				            <td className={styles.td50}>説明</td>
				        </tr>
				        <tr>
				            <td>border-top</td>
				            <td>上の線</td>
				        </tr>
				        <tr>
				            <td>border-bottom</td>
				            <td>下の線</td>
				        </tr>
				        <tr>
				            <td>border-left</td>
				            <td>左の線</td>
				        </tr>
				        <tr>
				            <td>border-right</td>
				            <td>右の線</td>
				        </tr>
				    </tbody>
				</table>
				  <p class="line">最後に、枠のスタイル、太さ、色を個別に指定する方法を説明します。<br/>
				上下左右のそれぞれの線の値を個別に指定する場合は、以下のプロパティを使用します。</p>
				<table border="1" class="tLine">
				    <tbody>
				        <tr className={styles.traqua}>
				            <td className={styles.td50}>プロパティ</td>
				            <td className={styles.td50}>説明</td>
				        </tr>
				        <tr>
				            <td>border-style</td>
				            <td>線のスタイル</td>
				        </tr>
				        <tr>
				            <td>border-width</td>
				            <td>線の太さ</td>
				        </tr>
				        <tr>
				            <td>border-color</td>
				            <td>線の色</td>
				        </tr>
				    </tbody>
				</table>
				  <p class="line">これらのプロパティは、共通して<br/>
				<span class="indent2em">・値を1つ指定した場合・・・「上下左右」にスタイルが適用される</span><br/>
				<span class="indent2em">・値を2つ指定した場合・・・記述した順に「上下」「左右」にスタイルが適用される</span><br/>
				<span class="indent2em">・値を3つ指定した場合・・・記述した順に「上」「左右」「下」にスタイルが適用される</span><br/>
				<span class="indent2em">・値を4つ指定した場合・・・記述した順に「上」「右」「下」「左」にスタイルが適用される</span><br/>
				このようなルールとなります。<br/>
				なお、値の区切りには半角スペースを使用します。</p>
				  <p sclassName={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				#sample1&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;border-style:&#160;solid&#160;double;<br/>
				&#x7D;<br/>
				<br/>
				#sample2&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;border-width:&#160;2px&#160;1px&#160;5px;<br/>
				&#x7D;<br/>
				<br/>
				#sample3&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;border-color:&#160;red&#160;blue&#160;green&#160;yellow;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/2-5-2.png" alt='2-5-2'/>
            </section>
            <section id='2-6'>
            <h3>２－６．複数の要素に同じスタイルを適用する</h3>
                <p class="line">セレクタをカンマ（,）で区切ると、複数のセレクタに対して同じスタイルをまとめて適用することができます。<br/> 
                なお、セレクタの使い方は、従来と変わりません。</p>
                <p className={styles.mb0}><span class="samplenum">例</span></p>
                <p class="sample">
				<br/>
				〜〜CSSの定義〜〜<br/>
				#sample1,&#160;#sample2&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-size:&#160;18px;<br/>
				&#x7D;<br/>
				<br/>
				h2,&#160;h3&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;color:&#160;#FF0000;<br/>
				&#x7D;<br/>
				<br/>
				〜〜HTML〜〜<br/>
				<span class="dec">&lt;!DOCTYPE&#160;html&gt;</span><br/>
				<span class="dec">&lt;html&gt;</span><br/>
				<span class="dec">&lt;head&gt;</span><br/>
				&#160;&#160;&#160;&#160;〜略〜<br/>
				<span class="dec">&lt;/head&gt;</span><br/>
				<span class="dec">&lt;body&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;p</span>&#160;<span class="attr">id</span>="sample1"<span class="dec">&gt;</span>りんご<span class="dec">&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;p</span>&#160;<span class="attr">id</span>="sample2"<span class="dec">&gt;</span>みかん<span class="dec">&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;p</span>&#160;<span class="attr">id</span>="sample3"<span class="dec">&gt;</span>ぶどう<span class="dec">&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;h1&gt;</span>いぬ<span class="dec">&lt;/h1&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;h2&gt;</span>ねこ<span class="dec">&lt;/h2&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;h3&gt;</span>うさぎ<span class="dec">&lt;/h3&gt;</span><br/>
				<span class="dec">&lt;/body&gt;</span><br/>
				<span class="dec">&lt;/html&gt;</span><br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/2-6-1.png" alt='2-6-1'/>

            </section>
            <section id='2-7'>
            <h3>２－７．子孫要素に同じスタイルを適用する</h3>
                <p class="line">セレクタを半角スペースで区切ると、 ある要素とその子孫要素に対して同じスタイルをまとめて適用することができます。<br/> 
                「子孫」とは、階層に関係なくその要素よりも深い階層にある要素を意味します。</p>
                <p className={styles.mb0}><span class="samplenum">例</span></p>
                <p class="sample">
				<br/>
				〜〜CSSの定義〜〜<br/>
				div&#160;p&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-size:&#160;18px;<br/>
				&#x7D;<br/>
				<br/>
				#sample&#160;.bold&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-weight:&#160;bold;<br/>
				&#x7D;<br/>
				<br/>
				〜〜HTML〜〜<br/>
				<span class="dec">&lt;!DOCTYPE&#160;html&gt;</span><br/>
				<span class="dec">&lt;html&gt;</span><br/>
				<span class="dec">&lt;head&gt;</span><br/>
				&#160;&#160;&#160;&#160;〜略〜<br/>
				<span class="dec">&lt;/head&gt;</span><br/>
				<span class="dec">&lt;body&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;div&gt;</span><br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>HTML<span class="dec">&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;span&gt;&lt;p&gt;</span>CSS<span class="dec">&lt;/p&gt;&lt;/span&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;/div&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;span</span>&#160;<span class="attr">id</span>="sample"<span class="dec">&gt;</span><br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>今日は<span class="dec">&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p</span>&#160;<span class="attr">class</span>="bold"<span class="dec">&gt;</span>とても<span class="dec">&lt;strong</span>&#160;<span class="attr">class</span>="bold"<span class="dec">&gt;</span>とても<span class="dec">&lt;/strong&gt;&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>暑い<span class="dec">&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;/span&gt;</span><br/>
				<span class="dec">&lt;/body&gt;</span><br/>
				<span class="dec">&lt;/html&gt;</span><br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/2-7-1.png" alt='2-7-1'/>
            </section>
            <section id='2-8'>
            <h3>２－８．子要素に同じスタイルを適用する</h3>
                <p class="line">セレクタを「&gt;」で区切ると、 ある要素とその子要素に対して同じスタイルをまとめて適用することができます。<br/> 
                「子」とは、その要素の直下にある要素のみを指します。（それ以上深い階層にある要素は「子」ではありません）</p>
                <p classname={styles.mb0}><span class="samplenum">例</span></p>
                <p class="sample">
				<br/>
				〜〜CSSの定義〜〜<br/>
				div&#160;&gt;&#160;p&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-size:&#160;18px;<br/>
				&#x7D;<br/>
				<br/>
				#sample&#160;&gt;&#160;.bold&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-weight:&#160;bold;<br/>
				&#x7D;<br/>
				<br/>
				〜〜HTML〜〜<br/>
				<span class="dec">&lt;!DOCTYPE&#160;html&gt;</span><br/>
				<span class="dec">&lt;html&gt;</span><br/>
				<span class="dec">&lt;head&gt;</span><br/>
				&#160;&#160;&#160;&#160;〜略〜<br/>
				<span class="dec">&lt;/head&gt;</span><br/>
				<span class="dec">&lt;body&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;div&gt;</span><br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>HTML<span class="dec">&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;span&gt;&lt;p&gt;</span>CSS<span class="dec">&lt;/p&gt;&lt;/span&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;/div&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;span</span>&#160;<span class="attr">id</span>="sample"<span class="dec">&gt;</span><br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>今日は<span class="dec">&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p</span>&#160;<span class="attr">class</span>="bold"<span class="dec">&gt;</span>とても<span class="dec">&lt;strong</span>&#160;<span class="attr">class</span>="bold"<span class="dec">&gt;</span>とても<span class="dec">&lt;/strong&gt;&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>暑い<span class="dec">&lt;/p&gt;</span><br/>
				&#160;&#160;&#160;&#160;<span class="dec">&lt;/span&gt;</span><br/>
				<span class="dec">&lt;/body&gt;</span><br/>
				<span class="dec">&lt;/html&gt;</span><br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/2-8-1.png" alt='2-8-1'/>
                <p class="line">「子」と「子孫」の違いは正確に覚えておいてください。</p>
            </section>
            <h2>３．ちょっとだけ高度な使い方</h2>
            <section id='3-1'>
            <h3>３－１．ボックスモデルについて</h3>
                <p class="line">CSSを正しく使いこなすためには、<span class="emphasis">ボックスモデル</span>と言う概念を理解することが必要不可欠です。<br/>
				この<span class="emphasis">ボックスモデル</span>とは、「<span class="emphasis">HTMLで定義された要素は、全て四角形のボックスを生成する</span>」と言う考え方です。<br/>
				正直なところ、CSSの勉強を始めたばかりの人にとっては、これを理解することは非常に厳しい話でもあります。<br/>
				しかし、いずれもっとCSSの理解が深まったした時には避けて通れない概念なので、今のところは先に挙げた「<span class="emphasis">要素はボックスを生成する</span>」んだと言うことと、次で説明する「<span class="emphasis">マージン、ボーダー、パディング</span>」の概念を覚えておいてください。</p>
            </section>
            <section id='3-2'>
            <h3>３－２．マージン、ボーダー、パディング</h3>
				  <p class="line"><span class="emphasis">ボックスモデル</span>では「<span class="emphasis">HTMLの要素はボックスの中に納められている</span>」と考えます。<br/>
				そして、このボックスには、<br/>
				<span class="indent2em"><span class="emphasis">・マージン</span></span><br/>
				<span class="indent2em"><span class="emphasis">・ボーダー</span></span><br/>
				<span class="indent2em"><span class="emphasis">・パディング</span></span><br/>
				の３つの大切なキーワードが存在します。<br/>
				それぞれが何を意味するものなのか、以下の図で見てみましょう。</p>
				<img class="sample" src="./static/images/css3/3-2-1.png" alt='3-2-1'/>
				<b>■マージン</b>
				  <p class="line">マージンは、要素全体の余白です。<br/>
				隣り合う要素との間にあるスペースと考えてください。<br/>
				マージンは<span class="emphasis">margin</span>プロパティで指定します。</p>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				#box&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;margin:&#160;10px;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				  <p class="line">値には、余白の大きさを指定します。<br/>
				また、<span class="emphasis">margin</span>プロパティに指定した値は上下左右に反映されますが、それぞれを個別に指定したい場合は<span class="emphasis">margin-top、margin-bottom、margin-left、margin-right</span>を使用します。</p>
				<b>■ボーダー</b>
				  <p class="line">ボーダーは、要素の枠です。<br/>
				２－５で説明した「枠」は、このボーダーのことを指します。</p>
				<b>■パディング</b>
				  <p class="line">パディングは、要素と枠の間の余白です。<br/>
				マージンとの違いは、マージンはパディングとボーダーを含めた「全体」に対しての余白なのに対して、パディングはHTML要素のみに対しての余白であると言う点です。<br/>
				なお、パディングは<span class="emphasis">padding</span>プロパティで指定します。</p>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				#box&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;padding:&#160;10px;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				  <p class="line">値には、余白の大きさを指定します。<br/>
				また、<span class="emphasis">margin</span>プロパティと同じく<span class="emphasis">padding</span>プロパティに指定した値は上下左右に反映されますが、それぞれを個別に指定したい場合は<span class="emphasis">padding-top、padding-bottom、padding-left、padding-right</span>を使用します。</p>

            </section>
            <section id='3-3'>
            <h3>３－３．リンクをカスタマイズする</h3>
                <p class="line">CSSを使うと、リンク（aタグ）に対して様々なカスタマイズを加えることが可能になります。<br/>
                ここでは、代表的なカスタマイズの方法を紹介します。</p>
                <b>■訪問済みリンクのカスタマイズ</b>
                <p class="line">「<span class="emphasis">訪問済みのリンク</span>」を指定するには、<span class="emphasis">タイプセレクタ</span>のaタグの後ろに「<span class="emphasis">:visited</span>」を付けます。</p>
                <p className={styles.mb0}><span class="samplenum">例</span></p>
                <p class="sample">
				<br/>
				a:visited&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;color:&#160;#FF0000;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/3-3-1.png" alt='3-3-1'/>
				<br/>
				<br/>
				<b>■未訪問リンクのカスタマイズ</b>
				  <p class="line">「<span class="emphasis">未訪問のリンク</span>」を指定するには、<span class="emphasis">タイプセレクタ</span>のaタグの後ろに「<span class="emphasis">:link</span>」を付けます。</p>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				a:link&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;color:&#160;#808080;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/3-3-2.png" alt='3-3-2'/>
				<br/>
				<br/>
				<b>■マウスを被せた場合のカスタマイズ</b>
				  <p class="line">リンクの上にマウスを被せている間だけに何らかのカスタマイズを加えたい場合は、<span class="emphasis">タイプセレクタ</span>のaタグの後ろに「<span class="emphasis">:hover</span>」を付けます。</p>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				a:hover&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;font-size:&#160;16pt;<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/3-3-3.png" alt='3-3-3'/>
            </section>
            <section id='3-4'>
                <h3>３－４．背景に画像を表示させる</h3>
                <p class="line"><span class="emphasis">background-image</span>プロパティを使用すると、HTML要素の背景に画像を表示することが出来ます。<br/>
                このプロパティは、<span class="emphasis">body</span>タグや<span class="emphasis">table</span>タグの他にも、<span class="emphasis">div</span>タグや<span class="emphasis">span</span>タグ、<span class="emphasis">p</span>タグなどで利用出来ます。</p>
                <p className={styles.mb0}><span class="samplenum">例</span></p>
                <p class="sample">
                <br/>
                #imageBox&#160;&#x7B;<br/>
                &#160;&#160;&#160;&#160;background-image:&#160;url("./img/sample.png");<br/>
                &#x7D;<br/>
                <br/>
                </p>
                <img class="sample" src="./static/images/css3/3-4-1.png" alt ='3-4-1'/>
            </section>
            <section id='3-5'>
            <h3>３－５．要素の直前、直後をカスタマイズする</h3>
                <p class="line">何らかのセレクタの後ろに「<span class="emphasis">:before</span>」を付けると、要素の直前に任意のコンテンツを挿入することが出来ます。<br/>
                挿入するコンテンツは<span class="emphasis">content</span>プロパティで指定します。<br/>
                なお、挿入可能な物には文字列や画像など、様々な種類があります。</p>
                <p className={styles.mb0}><span class="samplenum">例</span></p>
                <p class="sample">
				<br/>
				p:before&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;content:"■";<br/>
				&#x7B;<br/>
				<br/>
				#new:before&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;content:"★new★";<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/3-5-1.png" alt='3-5-1'/>
				  <p class="line">次に、「<span class="emphasis">:after</span>」を使えば要素の直後に任意のコンテンツを挿入することが出来ます。<br/>
				使い方等々は「<span class="emphasis">:before</span>」と同じです。</p>
				  <p className={styles.mb0}><span class="samplenum">例</span></p>
				  <p class="sample">
				<br/>
				.price:after&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;content:"円";<br/>
				&#x7D;<br/>
				<br/>
				.important:before&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;content:"【";<br/>
				&#x7D;<br/>
				<br/>
				.important:after&#160;&#x7B;<br/>
				&#160;&#160;&#160;&#160;content:"】";<br/>
				&#x7D;<br/>
				<br/>
				</p>
				<img class="sample" src="./static/images/css3/3-5-2.png" alt='3-5-2'/>
            </section>
            <section id='3-6'>
            <h3>３－６．条件に該当する要素だけを対象とする</h3>
                <p class="line">CSSには「条件に該当する要素」に対してスタイルを適用する術が多数用意されています。<br/>
                ここでは、それらの中からいくつかを紹介します。</p>
                <b>■最初に現れる子要素にスタイルを適用する</b>
                <p class="line">セレクタに「<span class="emphasis">:first-child</span>」を付けると、「ある要素内で最初に現れる子要素がセレクタにマッチする場合」にスタイルを適用することが出来ます。<br/>
                結構クセのある動きをすると感じるかもしれませんが、理解してしまえば単純な話なので、まずは例を見てください。</p>
                <p className={styles.mb0}><span class="samplenum">例</span></p>
                <p class="sample">
                <br/>
                p:first-child&#160;&#x7B;<br/>
                &#160;&#160;&#160;&#160;font-size:&#160;20pt;<br/>
                &#x7D;<br/>
                <br/>
                〜〜HTML〜〜<br/>
                <span class="dec">&lt;!DOCTYPE&#160;html&gt;</span><br/>
                <span class="dec">&lt;html&gt;</span><br/>
                <span class="dec">&lt;head&gt;</span><br/>
                &#160;&#160;&#160;&#160;〜略〜<br/>
                <span class="dec">&lt;/head&gt;</span><br/>
                <span class="dec">&lt;body&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>おはよう<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>こんにちは<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>こんばんわ<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;div&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>一番<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>二番<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>三番<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;/div&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;div&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;span&gt;</span>いちろう<span class="dec">&lt;/span&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>じろう<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>さぶろう<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;/div&gt;</span><br/>
                <span class="dec">&lt;/body&gt;</span><br/>
                <span class="dec">&lt;/html&gt;</span><br/>
                <br/>
                </p>
                <img class="sample" src="./static/images/css3/3-6-1.png" alt='3-6-1'/>
                <p class="line">まず、<span class="emphasis">body</span>タグの最初の子要素は「おはよう」の<span class="emphasis">p</span>タグなので、これにスタイルが適用されています。<br/>
                次に、一つ目の<span class="emphasis">div</span>タグの最初の子要素も「一番」の<span class="emphasis">p</span>タグなので、これにもスタイルが適用されています。<br/>
                そして、二つ目の<span class="emphasis">div</span>タグの最初の子要素は「いちろう」の<span class="emphasis">span</span>タグであり、「じろう」の<span class="emphasis">p</span>タグではないため、これらにはスタイルが適用されていません。<br/>
                これが「ある要素内で最初に現れる子要素がセレクタにマッチする場合」の具体的な動作です。</p>
                <b>■最後に現れる子要素にスタイルを適用する</b>
                <p class="line">「<span class="emphasis">:first-child</span>」に対して「<span class="emphasis">:last-child</span>」と言う物も存在します。<br/>
                これをセレクタに付けると、「ある要素内で最後に存在する子要素がセレクタにマッチする場合」にスタイルを適用することが出来ます。</p>
                <p className={styles.mb0}><span class="samplenum">例</span></p>
                <p class="sample">
                <br/>
                〜〜CSSの定義〜〜<br/>
                p:last-child&#160;&#x7B;<br/>
                &#160;&#160;&#160;&#160;font-size:&#160;20pt;<br/>
                &#x7D;<br/>
                <br/>
                〜〜HTML〜〜<br/>
                <span class="dec">&lt;!DOCTYPE&#160;html&gt;</span><br/>
                <span class="dec">&lt;html&gt;</span><br/>
                <span class="dec">&lt;head&gt;</span><br/>
                &#160;&#160;&#160;&#160;〜略〜<br/>
                <span class="dec">&lt;/head&gt;</span><br/>
                <span class="dec">&lt;body&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>おはよう<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>こんにちは<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>こんばんわ<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;div&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>一番<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>二番<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>三番<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;/div&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;div&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>いちろう<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>じろう<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;p&gt;</span>さぶろう<span class="dec">&lt;/p&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;/div&gt;</span><br/>
                <span class="dec">&lt;/body&gt;</span><br/>
                <span class="dec">&lt;/html&gt;</span><br/>
                <br/>
                </p>
                <img class="sample" src="./static/images/css3/3-6-2.png" alt='3-6-2'/>
                <p class="line">一つ目の<span class="emphasis">div</span>タグの最後の子である「三番」の<span class="emphasis">p</span>タグ、二つ目の<span class="emphasis">div</span>タグの最後の子である「さぶろう」の<span class="emphasis">p</span>タグにそれぞれスタイルが適用されています。<br/>
                なお、「こんばんわ」の<span class="emphasis">p</span>タグは<span class="emphasis">body</span>タグの最後子ではないため、スタイルが適用されていません。（<span class="emphasis">body</span>タグの最後の子は二つ目の<span class="emphasis">div</span>タグです）</p>
                <b>■偶数行、奇数行ごとにスタイルを適用する</b>
                <p class="line">「<span class="emphasis">:nth-child</span>」と言うセレクタを使うと、例えばリストの偶数行と奇数行にそれぞれ共通したスタイルを適用することが出来ます。<br/>
                これは実際によく使われるテクニックなので、是非覚えておいてください。</p>
                <p className={styles.mb0}><span class="samplenum">例</span></p>
                <p class="sample">
                <br/>
                〜〜CSSの定義〜〜<br/>
                tr:nth-child(odd)&#160;&#x7B;<br/>
                &#160;&#160;&#160;&#160;background-color:&#160;#A9A9A9;<br/>
                &#x7D;<br/>
                tr:nth-child(even)&#160;&#x7B;<br/>
                &#160;&#160;&#160;&#160;background-color:&#160;#F5F5F5;<br/>
                &#x7D;<br/>
                td&#160;&#x7B;<br/>
                &#160;&#160;&#160;&#160;border:&#160;solid&#160;1px&#160;black;<br/>
                &#x7D;<br/>
                <br/>
                〜〜HTML〜〜<br/>
                <span class="dec">&lt;!DOCTYPE&#160;html&gt;</span><br/>
                <span class="dec">&lt;html&gt;</span><br/>
                <span class="dec">&lt;head&gt;</span><br/>
                &#160;&#160;&#160;&#160;〜略〜<br/>
                <span class="dec">&lt;/head&gt;</span><br/>
                <span class="dec">&lt;body&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;table&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;tr&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;td&gt;</span>日本<span class="dec">&lt;/td&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;/tr&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;tr&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;td&gt;</span>アメリカ<span class="dec">&lt;/td&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;/tr&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;tr&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;td&gt;</span>ドイツ<span class="dec">&lt;/td&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;/tr&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;tr&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;td&gt;</span>フランス<span class="dec">&lt;/td&gt;</span><br/>
                &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;<span class="dec">&lt;/tr&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;/table&gt;</span><br/>
                <span class="dec">&lt;body&gt;</span><br/>
                <span class="dec">&lt;/html&gt;</span><br/>
                <br/>
                </p>
                <img class="sample" src="./static/images/css3/3-6-3.png" alt='3-6-3'/>
                <p class="line">「<span class="emphasis">:nth-child(odd)</span> 」が偶数行、「<span class="emphasis">:nth-child(even)</span> 」が奇数行をそれぞれ意味しています。<br/>
                なお、本来「<span class="emphasis">:nth-child</span>」は偶数行と奇数行を表すためのセレクタではなく、「何番目の子要素」と言う指定をするための物なのですが、ひとまずは今回紹介したような使い方が出来ることを覚えておいてもらえればと思います。</p>
                <b>■特定の属性を持つ要素だけを対象とする</b>
                <p class="line">「<span class="emphasis">要素名[属性名]</span>」の形式のセレクタは、特定の属性を持つ要素に対してスタイルを適用します。<br/>
                属性の種類は問いません。</p>
                <p className={styles.mb0}><span class="samplenum">例</span></p>
                <p class="sample">
                <br/>
                〜〜CSSの定義〜〜<br/>
                a[title]&#160;&#x7B;<br/>
                &#160;&#160;&#160;&#160;background-color:#C0C0C0;<br/>
                &#x7D;<br/>
                <br/>
                〜〜HTML〜〜<br/>
                <span class="dec">&lt;!DOCTYPE&#160;html&gt;</span><br/>
                <span class="dec">&lt;html&gt;</span><br/>
                <span class="dec">&lt;head&gt;</span><br/>
                &#160;&#160;&#160;&#160;〜略〜<br/>
                <span class="dec">&lt;/head&gt;</span><br/>
                <span class="dec">&lt;body&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;a</span>&#160;<span class="attr">href</span>="s.html"<span class="dec">&gt;</span>殿堂入り！<span class="dec">&lt;/a&gt;&lt;br&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;a</span>&#160;<span class="attr">href</span>="a.html"&#160;<span class="attr">title</span>="おすすめ"<span class="dec">&gt;</span>一番人気！<span class="dec">&lt;/a&gt;&lt;br&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;a</span>&#160;<span class="attr">href</span>="b.html"<span class="dec">&gt;</span>二番人気！<span class="dec">&lt;/a&gt;&lt;br&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;a</span>&#160;<span class="attr">href</span>="c.html"<span class="dec">&gt;</span>三番人気！<span class="dec">&lt;/a&gt;&lt;br&gt;</span><br/>
                <span class="dec">&lt;/body&gt;</span><br/>
                <span class="dec">&lt;/html&gt;</span><br/>
                <br/>
                </p>
                <img class="sample" src="./static/images/css3/3-6-4.png" alt='3-6-4'/>

                <b>■特定の属性、属性値を持つ要素だけを対象とする</b>
                <p class="line">「<span class="emphasis">要素名[属性名="属性値"]</span>」の形式のセレクタは、特定の属性と属性値を持つ要素に対してスタイルを適用します。<br/>
                属性や値の種類は問いません。</p>
                <p className={styles.mb0}><span class="samplenum">例</span></p>
                <p class="sample">
                <br/>
                〜〜CSSの定義〜〜<br/>
                a[href="s.html"]&#160;&#x7B;<br/>
                &#160;&#160;&#160;&#160;background-color:#C0C0C0;<br/>
                &#x7D;<br/>
                <br/>
                〜〜HTML〜〜<br/>
                <span class="dec">&lt;!DOCTYPE&#160;html&gt;</span><br/>
                <span class="dec">&lt;html&gt;</span><br/>
                <span class="dec">&lt;head&gt;</span><br/>
                &#160;&#160;&#160;&#160;〜略〜<br/>
                <span class="dec">&lt;/head&gt;</span><br/>
                <span class="dec">&lt;body&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;a</span>&#160;<span class="attr">href</span>="s.html"<span class="dec">&gt;</span>殿堂入り！<span class="dec">&lt;/a&gt;&lt;br&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;a</span>&#160;<span class="attr">href</span>="a.html"&#160;<span class="attr">title</span>="おすすめ"<span class="dec">&gt;</span>一番人気！<span class="dec">&lt;/a&gt;&lt;br&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;a</span>&#160;<span class="attr">href</span>="b.html"<span class="dec">&gt;</span>二番人気！<span class="dec">&lt;/a&gt;&lt;br&gt;</span><br/>
                &#160;&#160;&#160;&#160;<span class="dec">&lt;a</span>&#160;<span class="attr">href</span>="c.html"<span class="dec">&gt;</span>三番人気！<span class="dec">&lt;/a&gt;&lt;br&gt;</span><br/>
                <span class="dec">&lt;/body&gt;</span><br/>
                <span class="dec">&lt;/html&gt;</span><br/>
                <br/>
                </p>
                <img class="sample" src="./static/images/css3/3-6-5.png" alt='3-6-5'/>
            </section>
        </div>
    </Grid>
    <Grid item xs={12} md={4}>
          <div className="ad">
            広告エリア2
          </div>
        </Grid>
    </Grid>
    </div>
    </Zoom>
);
}