import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ScrollToTopOnMount from '../Common/ScrollToTopOnMount';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import '../Common/Common.css';
import Grow from '@material-ui/core/Grow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const useStyles = theme => ({
  Th: {
    border: '1px solid #ccc',
    padding: '15px',
    fontWeight: 'bold',
    backgroundColor: '#dedede',
    textAlign: 'center',
    margin: '2px',
    height: '95%',
  },
  Td: {
    border: '1px solid #ccc',
    padding: '15px',
    margin: '2px',
    height: '95%',
  },
  image:{
    maxWidth: '100%',
    height: 'auto',
    marginTop: '10px',
    marginBottom: '10px',
  },
  paper: {
    margin: theme.spacing(1),
    backgroundColor: 'transparent',
  },
});

class Company extends React.Component {
  imgclick(e) {
    let offsetX = e.nativeEvent.offsetX; // =>要素左上からのx座標
    let offsetY = e.nativeEvent.offsetY; // =>要素左上からのy座標

    let xx = 480 / 234;
    let yy = 640 / 294;
    let x = Math.floor(e.target.width / xx);
    let y = Math.floor(e.target.height / yy);
    if (offsetX === x && offsetY === y) {
      alert('ウッディエンゼルハイボール！！！');
    }
  }
  render() {
    return (
      <Grow in={true}>
        <div>
          <ScrollToTopOnMount />
          <Typography variant='h6' className='Sub-title' gutterBottom>
            <Box fontWeight='fontWeightBold'>社長挨拶</Box>
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <img usemap="#ImageMap" onClick={(e) => this.imgclick(e)} alt="" className={this.props.classes.image} src={`${process.env.PUBLIC_URL}/static/images/boss.JPG`} />
            </Grid>
            <Grid item xs={12} sm={8}>
            <Paper elevation={0} className={this.props.classes.paper}>
              <Typography variant='h6'>やる時はやる。</Typography>
              <Typography variant='h6'>遊ぶ時は全力で遊ぶ。</Typography>
              <Typography variant='h6' paragraph gutterBottom>酒も全力で飲む。</Typography>
              <Typography variant='body1'>クロスボーンはそんな会社です。</Typography>
              <Typography variant='body1' paragraph gutterBottom>つまらない固定観念は捨て去って、短い人生を全力で楽しみましょう。</Typography>
              <Typography variant='body1' paragraph gutterBottom>代表取締役　五十嵐 肇</Typography>
            </Paper>
            </Grid>
          </Grid>
          <Typography variant='h6' className='Sub-title' gutterBottom>
            <Box fontWeight='fontWeightBold'>会社概要</Box>
          </Typography>
          <Typography variant='body2'>
          <Grid container>
            <Grid item xs={12} md={4}>
              <Paper className={this.props.classes.Th}>会社名</Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper className={this.props.classes.Td}>有限会社クロスボーン</Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper className={this.props.classes.Th}>代表取締役</Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper className={this.props.classes.Td}>五十嵐 肇</Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper className={this.props.classes.Th}>本社所在地</Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper className={this.props.classes.Td}>神奈川県川崎市高津区末長４−１３−２</Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper className={this.props.classes.Th}>資本金</Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper className={this.props.classes.Td}>1000万円</Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper className={this.props.classes.Th}>設立日</Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper className={this.props.classes.Td}>2005年12月28日</Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper className={this.props.classes.Th}>業務内容</Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper className={this.props.classes.Td}>ITシステムの開発・運用・保守、課題解決、マネジメントなど、なんでもやります</Paper>
            </Grid>
          </Grid>
          </Typography>
        </div>
      </Grow>
    );
  }
}
export default withStyles(useStyles)(Company);
