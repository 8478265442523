import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import LinkBtn from '@material-ui/core/Link';

const drawerWidth = 220;
const useStyles = theme => ({
  footer: {
    width: '100%',
    height: '180px',
    textAlign: 'center',
    padding: '10px 0',
    marginTop: 'auto',
    backgroundColor: '#35354B',
    color: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      height: '100px',
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  button: {
    color: '#ffffff',
    textDecoration: 'none',
  },
  nav: {
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  nav_item: {
    display: 'list-item',
    listStyleType: 'none',
    textTransform: 'uppercase',
    padding: '0.5em',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
  },
});

class Footer extends React.Component {

  render() {
    return (
      <footer className={this.props.classes.footer}>
        <nav className={this.props.classes.nav}>
          <ul>
            <li className={this.props.classes.nav_item}><LinkBtn component={Link} className={this.props.classes.button} to="/">ホーム</LinkBtn></li>
            <li className={this.props.classes.nav_item}><LinkBtn component={Link} className={this.props.classes.button} to="/company">会社概要</LinkBtn></li>
            <li className={this.props.classes.nav_item}><LinkBtn component={Link} className={this.props.classes.button} to="/poricy">プライバシーポリシー</LinkBtn></li>
          </ul>
        </nav>
        <p>© {new Date().getFullYear()} x-born.net</p>
      </footer>

    );
  }
}
export default withStyles(useStyles) (Footer);
