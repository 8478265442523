
import React from 'react';
import ScrollToTopOnMount from '../Common/ScrollToTopOnMount';
import Zoom from '@material-ui/core/Zoom';
import '../Common/Common.css';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

class Poricy extends React.Component {

  render() {
    return (
      <Zoom in={true}>
        <div>
          <ScrollToTopOnMount />
          <Typography variant='h6' className='Sub-title' gutterBottom>
            <Box fontWeight='fontWeightBold'>プライバシーポリシー</Box>
          </Typography>
          <Typography variant='body2' paragraph gutterBottom>
            有限会社クロスボーンは、お客様の個人情報の保護に関し、以下の「個人情報保護方針」を定め、その保護活動を実施しております。
          </Typography>
          <Typography variant='h6' gutterBottom>
            <Box fontWeight='fontWeightBold'>個人情報保護方針</Box>
          </Typography>
          <Typography variant='body2' align='right' gutterBottom>制定日：2005年 12月28日</Typography>
          <Typography variant='body2' align='right' gutterBottom>改定日：2020年 1月 1日</Typography>
          <Typography variant='body2' align='right' gutterBottom>有限会社クロスボーン</Typography>
          <Typography variant='body2' align='right' gutterBottom>代表取締役　五十嵐肇</Typography>
          <Typography variant='body2' paragraph gutterBottom>
          有限会社クロスボーン（以下、「当社」という）は、お客様の個人情報の取り扱いについて以下の取り組みを実施し、事業を行っていくことを宣言いたします。<br />
          なお、この取り組みは当社の従業員についても同様といたします。
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            <Box fontWeight='fontWeightBold'>(1) 個人情報の収集・利用・提供について</Box>
          </Typography>
          <Typography variant='body2' paragraph gutterBottom>
          当社は、個人情報を保護・管理する体制を確立し、個人情報の取得にあたっては事業遂行のために必要な範囲内で利用目的を明確にした上で行います。<br />
          取得した個人情報は利用目的の範囲内でのみ利用し、目的外利用を行わないための措置を講じます。<br />
          取得した個人情報の取扱いの全部または一部を委託する場合には、充分な保護水準を満たした者を選定し、契約等により適切な措置を講じます。
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            <Box fontWeight='fontWeightBold'>(2) 個人情報の安全対策</Box>
          </Typography>
          <Typography variant='body2' paragraph gutterBottom>
          個人情報に対するリスク（個人情報への不正アクセス、個人情報の漏洩、紛失、破壊、改竄）に対して、合理的な対策をとり、個人情報の安全性・正確性を確保いたします。<br />
          また、必要に応じて対策方法の見直し及び是正を行います。
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
          <Box fontWeight='fontWeightBold'>(3) 個人情報に関する法令・規範の遵守</Box>
          </Typography>
          <Typography variant='body2' paragraph gutterBottom>
            個人情報の取扱いに関する法令・国が定める指針とその他の規範を常に意識し、その内容を遵守いたします。
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            <Box fontWeight='fontWeightBold'>(4) 苦情・相談への対応</Box>
          </Typography>
          <Typography variant='body2' paragraph gutterBottom>
            個人情報保護の苦情及び相談に関しては、窓口体制と対応手順を定め、適切に対応いたします。
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            <Box fontWeight='fontWeightBold'>(5) 個人情報保護に関する連絡先</Box>
          </Typography>
          <Typography variant='body2' paragraph gutterBottom>
          代表取締役　五十嵐肇<br />
          メール:info@x-born.net<br />
          電話番号：090-5399-9057
          </Typography>
          <br />
          <Typography variant='h6' gutterBottom>
            <Box fontWeight='fontWeightBold'>開示対象個人情報に関する事項の周知について</Box>
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            <Box fontWeight='fontWeightBold'>1. 会社名称</Box>
          </Typography>
          <Typography variant='body2' paragraph gutterBottom>
            有限会社クロスボーン
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            <Box fontWeight='fontWeightBold'>2. 個人情報保護管理者</Box>
          </Typography>
          <Typography variant='body2' paragraph gutterBottom>
            代表取締役　五十嵐肇
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            <Box fontWeight='fontWeightBold'>3. 開示対象個人情報の利用目的</Box>
          </Typography>
          <Typography variant='body2' paragraph gutterBottom>
          (1) お客様に関する開示対象個人情報<br />
          商品・サービスの提供・提案、お客様と連絡・契約履行、お取引先との取次・媒介等の目的に必要な範囲で、当社の関係会社、お取引先に提供することがある。
          </Typography>
          <Typography variant='body2' paragraph gutterBottom>
          (2) お取引先の皆様に関する開示対象個人情報<br />
          お取引先との連絡、契約履行等の目的に必要な範囲で、当社の関係会社、お取引先等に提供することがある。
          </Typography>
          <Typography variant='body2' paragraph gutterBottom>
          (3) 株主に関する開示対象個人情報<br />
          商法に基づく権利・義務の行使・履行、株主地位に関する当社からの便宜供与、各種法令に基づくデータ作成等のために活用する。
          </Typography>
          <Typography variant='body2' paragraph gutterBottom>
          (4) 当社へ入社希望の方に関する開示対象個人情報<br />
          連絡・情報提供及び採用選考等のために活用する。
          </Typography>
          <Typography variant='body2' paragraph gutterBottom>
          (5) 社員に関する開示対象個人情報<br />
          当社の人事・給与・年金 等の人事管理、お取引先に対して社員の技術スキル情報の提供等に活用する。
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            <Box fontWeight='fontWeightBold'>4. 開示対象個人情報取扱いの苦情申し出先</Box>
          </Typography>
          <Typography variant='body2' paragraph gutterBottom>
          代表取締役　五十嵐肇<br />
          info@x-born.net
          </Typography>
          <Typography variant='subtitle2' gutterBottom>
            <Box fontWeight='fontWeightBold'>5. 開示等の請求に応じる手続</Box>
          </Typography>
          <Typography variant='body2' paragraph gutterBottom>
          開示等の請求に応じる手順は下記の通りです。<br />
          (1) 上記苦情・相談窓口責任者にご相談ください<br />
          (2) 個人情報の開示等の申請書をお渡しいたします<br />
          (3) 申請書に必要事項を記入の上、ご提出ください<br />
          (4) 苦情・相談窓口責任者が早急に対応いたします
          </Typography>
        </div>
      </Zoom>
    );
  }
}
export default Poricy;
